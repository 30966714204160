import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { CommanService } from '../shared/services/comman.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  data = [];
  itemsTotal = 0;
  activePage = 0;
  isLoading = true;
  isPageLoading = true;
  searchTerm = '';
  roles = '';
  rowset = ['20', '40', '60', '80', '100', '120', '140', '160', '180', '200'];

  public filterList = [
    { id: '', title: 'All' },
    { id: 'SP', title: 'Service Provide' },
    { id: 'C', title: 'Centre' },
    { id: 'U', title: 'User' }
  ];

  constructor(
    private _commanService: CommanService) { }

  ngOnInit() {
    this.getPayment();
  }
  getPayment() {
    if (this.roles === 'all') { this.roles = '' }
    const path = `/user/get-all-Payments?role=${this.roles}&search=`;
    this._commanService.get(path).subscribe((res: any) => {
      this._commanService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        this.data = res.data;
        // console.log('res????', this.data)
        this.itemsTotal = res.data.length;

        // console.log('res????', res.data.length)
        if (res.data && res.data.length >= 1) {
          this.activePage = 1;
        }
        if (res.data && res.data.length > 200) {
          this.rowset.push(res.data.length);
        }

        //
      } else {
        // this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.isLoading = false;
      this.isPageLoading = false;
    });
  }
  search(evt) {
    console.log('evt', evt);
  }
  public userFilter(evt) {
    this._commanService.loader('show');
    if (evt.target.value === 'all') {
      this.getPayment();
    } else {
      // let language   =  this.lang;
      this.roles = evt.target.value;
      this.getPayment();
    }
  }
}

