import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { CommanService } from '../../shared/services/comman.service';
import { DialogService } from "ng2-bootstrap-modal";
import { tsConstants } from '../../tsconstant';
import { tsMessages } from '../../tsmessage';
import { CookieService } from 'ngx-cookie';
import { FlashMessagesService } from 'ngx-flash-messages';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss']
})
export class AddSectionComponent {
  public user = {
    firstName: '',
    lastName: '',
    roles: '',
    email: '',
    mobile: '',
    affiliate: 'yes'
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _commanService: CommanService,
    private _cookieService: CookieService,
    private _dialogService: DialogService,
    private _flashMessagesService: FlashMessagesService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  // save() {
  //   let data = JSON.parse(JSON.stringify(this.user));
  //   console.log('data', data, data.roles);
  //     const payload = {
  //       email: data.email,
  //       firstName: data.email,
  //       lastName: data.lastName,
  //       phone:data.mobile,
  //       role:data.roles,
  //       isVipAffiliateUser: data.affiliate === 'yes',
  //       addedBy: '',
  //       numberOfUser: ''
  //     }
  //     const path = decodeURI(`/user​/request`);
  //     console.log('path', path);
  //     this._userService.loader('show');
  //     this._userService.post(path, payload).subscribe((res: any) => {
  //       console.log('res', res)
  //       this._userService.loader('hide');
  //       if (res.success) {

  //         this.toastr.success(res.data.message);
  //         this._router.navigate(['/users/list']);
  //       } else {
  //         this._cookieService.put('categoryExistAlert', res.error.message);
  //       }
  //     }, err => {

  //     });
  // }
  save() {
    let data = JSON.parse(JSON.stringify(this.user));
    console.log('data', data, data.roles);
    const payload = {
      email: data.email,
      firstName: data.email,
      lastName: data.lastName,
      phone: data.mobile,
      role: data.roles,
      isVipAffiliateUser: data.affiliate === 'yes',
      addedBy: localStorage.getItem('adminId'),
      numberOfUser: 0
    }
    // const path = "/user/request";
    // console.log('path', path);
    this._userService.loader('show');
    this._userService.requestUrl('user', 'request', payload).subscribe((res: any) => {
      this._userService.loader('hide');
      if (res.statusCode === 200) {
        this.toastr.success(res.message);
        this._router.navigate(['/users/list']);
      } else {
        this.toastr.error(res.message);
        this._cookieService.put('categoryExistAlert', res.error.message);
      }
    }, err => {
      this._userService.loader('hide');
      this.toastr.error(err);
    });
  }
  showDangerAlert(): void {

    let alertMessage = this._cookieService.get('categoryExistAlert');
    if (alertMessage) {
      this._flashMessagesService.show(alertMessage, {
        classes: ['alert', 'alert-danger'],
        timeout: 3000,
      });
      this._cookieService.remove('categoryExistAlert');
    }
  }

  trim(key) {
    if (this.user[key] && this.user[key][0] == ' ') this.user[key] = this.user[key].trim();
  }

  paymentOption(value) {
    if (value == 'no') {
      var retVal = confirm("Subscription will not be free for this option!");
      if (retVal == true) {
        this.user.affiliate = 'no';
        return true;
      } else {
        this.user.affiliate = '';
        setTimeout(() => {
          this.user.affiliate = 'yes';
        }, 100)
      }
    } else {
      this.user.affiliate = 'yes';
    }



  }

}



