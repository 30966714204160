import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule} from "angular2-datatable";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { ImageUploadModule } from 'ng2-imageupload';
import { ChartsModule } from 'ng2-charts';

import { SharedModule } from '../shared/shared.module';
import { SubscriberRoutingModule } from './subscriber-routing.module';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

 
@NgModule({
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    SubscriberRoutingModule,
    CommonModule,
    DataTableModule,
    FlashMessagesModule,
    SharedModule,
    ChartsModule,
    ImageUploadModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
  providers: [ ],
  declarations: [SubscriberComponent],
  entryComponents: []
})
export class SubscriberModule { }
