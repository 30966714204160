import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { CommanService } from '../../shared/services/comman.service';
import { DialogService } from "ng2-bootstrap-modal";
import { tsConstants } from '../../tsconstant';
import { tsMessages } from '../../tsmessage';
import { CookieService } from 'ngx-cookie';
import { FlashMessagesService } from 'ngx-flash-messages';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-section',
  templateUrl: './edit-section.component.html',
  styleUrls: ['./edit-section.component.scss']
})
export class EditSectionComponent implements OnInit {
  public userID = '';
  public user = {
    firstName: '',
    lastName: '',
    roles: '',
    email: '',
    phone: ''

  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _commanService: CommanService,
    private _cookieService: CookieService,
    private _dialogService: DialogService,
    private _flashMessagesService: FlashMessagesService,
    private toastr: ToastrService) {
    this._userService.loader('show');
    this.userID = _route.snapshot.params['id'];
    this._userService.get(this.userID).subscribe((res: any) => {
      console.log('res----', res);
      if (res.statusCode === 200) {
        this._userService.loader('hide');
        this.user = res.data[0];

      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this._userService.loader('hide');

    });
  }

  ngOnInit() {
  }

  save() {
    this._userService.loader("show");
    let data = JSON.parse(JSON.stringify(this.user));
    console.log('>>>>>>>>>>>>>>>>', data);
    this._userService.put(data).subscribe((res: any) => {
      this._userService.loader('hide');
      if (res.success) {

        this.toastr.success("Updated successfully");
        this._router.navigate(['/users/list']);
      } else {
        this.toastr.error("Some error occuerd");
        this.showDangerAlert();
      }
    }, err => {

    });
  }


  showDangerAlert(): void {
    let alertMessage = this._cookieService.get('categoryExistAlert');
    if (alertMessage) {
      this._flashMessagesService.show(alertMessage, {
        classes: ['alert', 'alert-danger'],
        timeout: 3000,
      });
      this._cookieService.remove('categoryExistAlert');
    }
  }

  trim(key) {
    if (this.user[key] && this.user[key][0] == ' ') this.user[key] = this.user[key].trim();
  }

}



