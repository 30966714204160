import { Component, OnInit } from '@angular/core';
import { CommanService } from '../../shared/services/comman.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { tsConstants } from '../../tsconstant';
import { tsMessages } from '../../tsmessage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user = {
    email: '',
    password: '',
    termsCondition: true,
    loginType: 'SA'
  };

  public errMessage = '';
  public isPageLoading = false;
  public rememberMe = true;
  private _session = false;

  constructor(
    private _router: Router,
    private _commanService: CommanService,
    private _cookieService: CookieService,
    private _activateRouter: ActivatedRoute,
    private toastr: ToastrService) {

    this._session = _activateRouter.snapshot.params.data;
    if (this._session) {
      this.errMessage = tsMessages.YOUR_SESSION_HAS_EXPIRED_PLEASE_LOGIN_AGAIN;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('remember')) {
      this.user.email = localStorage.getItem('remember');
    }
  }


  login() {

    const data = JSON.parse(JSON.stringify(this.user));
    console.log('data', data);
    this._commanService.loader('show');
    this._commanService.login(data).subscribe((res: any) => {
      this._commanService.loader('hide');
      localStorage.setItem('adminId', res.data._id);

      const token = res.data.loginToken;

      this.routeNavigate(token, res.data);
      this.toastr.success('Login successfully.');
      // this.setValue(res);

      let actions;
      // if (res.role_id && res.role_id['permission']) {

      //     actions = res.role_id['permission'];
      //     actions['type'] = res.roles;
      //     this.routeNavigate(token, actions);

      // } else if (res.roles == 'SA') {

      //     actions = {
      //         type: res.roles
      //     };
      //     this.routeNavigate(token, actions);
      //     this.toastr.success("Login successfully.");

      // } else {
      //     this._commanService.loader('hide');
      //     this.errMessage = tsMessages.YOU_ARE_NOT_AUTHORIZED_PLEASE_CONTACT_MARKET_ADMIN;
      // }

    }, err => {
      this._commanService.loader('hide');
      this.errMessage = tsMessages.EMAIL_OR_PASSWORD_IS_NOT_CORRECT;
    });

  }


  setValue(res) {
    const mObj = {
      markets: [],
      selectedMarket: {
        name: 'All',
        pincode: []
      }
    };
    this._cookieService.putObject('markets', mObj);
  }

  routeNavigate(token, actions) {
    /* Setup Cookie */
    this._cookieService.put('token', token);
    this._cookieService.putObject('actions', actions);

    const route = '/dashboard';
    this._router.navigate([route]);
    // if (this.rememberMe) {
    //     localStorage.setItem("remember", this.user["email"]);
    // } else {
    //     localStorage.removeItem('remember');
    // }
    // if (!this._session) {

    //     let route = '/dashboard';
    //     this._router.navigate([route]);

    // } else {
    //     this._commanService.back();
    // }
  }


}
