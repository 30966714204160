import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ngx-pagination-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ChildRouteGuard } from '../auth/services/child-route-guard';
import { CmsService } from '../cms/services/cms.service';
import { ListCmsComponent } from './list-cms/list-cms.component';
import { AddCmsComponent } from './add-cms/add-cms.component';
import { ViewCmsComponent } from './view-cms/view-cms.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'CMS'
    },
    children: [
      {
        path: '',
        component: ListCmsComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListCmsComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      {
        path: 'list/:id',
        component: ViewCmsComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'View'
        }
      },
      {
        path: 'edit/:id',
        component: AddCmsComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'Edit'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    FormsModule,
    Ng2TableModule,
    PaginationModule,
    HttpClientModule
  ],

  providers: [
    CmsService
  ],
  exports: [RouterModule,
    FormsModule,
    Ng2TableModule,
    PaginationModule]
})
export class CmsRoutingModule { }
