import { Component, ChangeDetectorRef } from '@angular/core';
import { SubscriptionPlanService } from '../services/subscription-plan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CommanService } from '../../shared/services/comman.service';
import { FlashMessagesService } from 'ngx-flash-messages';
import { tsMessages } from '../../tsmessage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edi-subscription',
  templateUrl: './edi-subscription.component.html',
  styleUrls: ['./edi-subscription.component.scss']
})
export class EdiSubscriptionComponent {
  public category = {

    type: '',
    name: '',
    planType: '',
    name_type: '',
    validateDay: null,
    max_resources: '',
    price: 0,
    professionalProfile: false,
    addressURLLogoPicture: false,
    videoUpload: false,
    byCompare: false,
    socialMediaSharing: false,
    performanceDashboard: false,
    realReviewsAndFeedback: false,
    receiveMessagesForBooking: false,
    sendMessagesForRecalls: false,
    promotionsAndDiscountsOption: false,
    listProductsOption: false,
    listAnySpecialAmenities: false,
    listOfProviders: false,
    listYourTypeOfCenterAndDifferentLocations: false
  };
  public ID = '';
  public isLoading = false;
  public isPageLoading = true;
  public categoryID: any;
  public oBj = { vname: '' };
  public response: any;
  // public type;
  public errMessage = '';
  public ParentCategories = [];

  public names = [];
  public rowsOnPage = '';
  public activePage = '';
  public sortTrem = '';
  public data = [];
  public itemsTotal = 0;
  public searchTerm = '';
  public datatype = [];



  constructor(
    private _router: Router,
    private _activateRouter: ActivatedRoute,
    private _catgService: SubscriptionPlanService,
    private _flashMessagesService: FlashMessagesService,
    private _cookieService: CookieService,
    private _commanService: CommanService,
    private _activatedRouter: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService) {

    this.ID = _activatedRouter.snapshot.params['id'];
      console.log('this.ID)', this.ID);
    // if (this.ID) {
    //   this._catgService.loader('show');
    //   this._catgService.get(this.ID).subscribe((res: any) => {
    //     this._catgService.loader('hide');
    //     if (res.success) {
    //       this.category = res.data;
    //       console.log(" this.category>>>", this.category)
    //       /*console.log(res.data.type);
    //       this.category.type = this.firstUpper(this.category.type);*/
    //     } else {
    //       this.errMessage = res.error.message;
    //       this._commanService.checkAccessToken(res.error);
    //     }
    //   }, err => {

    //     this._catgService.loader('hide');
    //     this._commanService.checkAccessToken(err);
    //   });
    // }

  }

  ngOnInit() {
    // this.getAllName();
    // this.getAllType();
    this.showDangerAlert();
  }

  /*If categoryID exist then will update existing category otherwise will add new category*/
  save() {

    this.isLoading = true;
    let data = JSON.parse(JSON.stringify(this.category));


    this._catgService.loader('show');
    this._catgService.update(data).subscribe((res: any) => {
      this._catgService.loader('hide');
      if (res.success) {
        this.response = res;
        this.toastr.success(res.data.message);
        this._router.navigate(['/subscriptionplan/list']);
      } else {
        this._cookieService.put('categoryExistAlert', res.error.message);
        this.showDangerAlert();
      }
    }, err => {
      this.isLoading = false;
    });


  }

  showDangerAlert(): void {

    let alertMessage = this._cookieService.get('categoryExistAlert');
    if (alertMessage) {
      this._flashMessagesService.show(alertMessage, {
        classes: ['alert', 'alert-danger'],
        timeout: 3000,
      });
      this._cookieService.remove('categoryExistAlert');
    }
  }

  trim(key) {
    if (this.category[key] && this.category[key][0] == ' ') this.category[key] = this.category[key].trim();
  }

  // getAllName() {
  //   this._catgService.loader('show');
  //   this._catgService.getAllName(this.rowsOnPage, this.activePage, this.sortTrem, this.searchTerm).subscribe((res: any) => {
  //     this._catgService.loader('hide');
  //     this.isLoading = false;
  //     this.isPageLoading = false;
  //     if (res.success) {
  //       this.data = res.data.subscribenames;
  //       // console.log('>>>>>>>>>>>', this.data)
  //       this.itemsTotal = res.data.total;
  //       // this.showAlert();
  //     } else {
  //       this._commanService.checkAccessToken(res.error);
  //     }
  //   }, err => {
  //     // this.isLoading     = false;
  //     // this.isPageLoading = false;
  //     // this._commanService.checkAccessToken(err);
  //   });
  // }

  // getAllType() {
  //   this._catgService.loader('show');
  //   this._catgService.getAllType(this.rowsOnPage, this.activePage, this.sortTrem, this.searchTerm).subscribe((res: any) => {
  //     this._catgService.loader('hide');
  //     this.isLoading = false;
  //     this.isPageLoading = false;
  //     if (res.success) {
  //       this.datatype = res.data.subscribetypes;
  //       console.log('>>>>>>>>>>>', this.data)
  //       this.itemsTotal = res.data.total;
  //       // this.showAlert();
  //     } else {
  //       this._commanService.checkAccessToken(res.error);
  //     }
  //   }, err => {
  //     // this.isLoading     = false;
  //     // this.isPageLoading = false;
  //     // this._commanService.checkAccessToken(err);
  //   });
  // }

}
