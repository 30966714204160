import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ngx-pagination-bootstrap';
import { ListingComponent } from './listing/listing.component';
import { ChildRouteGuard } from '../auth/services/child-route-guard';
import { HttpClientModule } from '@angular/common/http';
import { CategoryService } from '../category-management/services/category.service';
const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Category'
    },
    children: [
      {
        path: '',
        component: ListingComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      //   {
      //     path: 'add',
      //     component: AddUpdateBlogsComponent,
      //     // canActivate: [ChildRouteGuard],
      //     data: {
      //       title: 'Add Blogs'
      //     }
      //   },
      //   {
      //     path: 'list/:id',
      //     component: ViewBlogsComponent,
      //     // canActivate: [ChildRouteGuard],
      //     data: {
      //       title: 'View Blogs'
      //     }
      //   },
      //   {
      //     path: 'edit/:id',
      //     component: AddUpdateBlogsComponent,
      //     // canActivate: [ChildRouteGuard],
      //     data: {
      //       title: 'Edit Blogs'
      //     }
      //   }

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    HttpClientModule,
    Ng2TableModule,
    PaginationModule,
  ],
  providers: [
    CategoryService
  ],
  exports: [
    RouterModule,
    FormsModule,
    Ng2TableModule,
    PaginationModule
  ]
})
export class CategoryRoutingModule { }
