import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CommanService } from '../../shared/services/comman.service';
import { tsConstants } from '../../tsconstant';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class UserService {

  private _host = tsConstants.HOST;

  constructor(
    private _http: HttpClient,
    private _commanService: CommanService,
    private spinner: NgxSpinnerService
  ) {
  }

  /*Use to get all Users*/
  getAllUsers(roles = '', search = '', rowsOnPage = '', activePage = '', sortBy = 'createdAt', sortOrder='desc') {
    const date = new Date().getTime().toString();
    const url = this._host + '/user/get-all-user?role=' + roles + '&count=' + rowsOnPage + '&page=' + activePage + '&search=' + search + '&sortBy=' + sortBy + '&sortOrder=' + sortOrder;
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  getAllAffiliateUsers(rowsOnPage, activePage, search = '') {
    const date = new Date().getTime().toString();
    const url = this._host + '/user/get-affiliate-request?isVipAffiliateUser=true&count='
      + rowsOnPage + '&page=' + activePage + '&search=' + search;

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }


  /*Use to add new user*/
  add(user) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/user', user, { headers });
  }
  addVipUser(user) {
    console.log('user', user);
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + `/user​/request`, user, { headers });
  }
  put(body) {
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.put(this._host + '/user/' + body.id, body, { headers });
  }

  /*User to get user detail with ID*/
  get(userid) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/user/get-profile-by-sa/' + userid, { headers });
  }

  /*Use to get partner detail with ID */
  getPartner(userid){
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/partner/get/' + userid, { headers });
  }

  /*Use to update user detail with there ID*/
  update(user) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.put(this._host + '/user/' + user.id, user, { headers });
  }

  changeStatus(id, status) {
    const data = {
      id, status
    };
    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + `/user/updateStatus`;
    return this._http.put(url, data, { headers });
  }

  updateBadge(id, badge, value) {
    const data = {
      id,
      [badge]: value
    };
    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + `/user/updateBadge`;
    return this._http.put(url, data, { headers });
  }
  /*Use to fetch all States*/
  getStates() {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/states?sort=stateName', { headers });
  }
  getCertificates(userId) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/certificate/get-by-userid/' + userId, { headers });
  }

  downloadFile(filepath: string, filename: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const headers = this._commanService.getAuthorizationHeader();
      this._http.post(this._host + '/common/file-download', {fileKey: filepath}, { headers, responseType: 'blob' }).subscribe((res: any) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);        
        a.download = filename;
        a.click();
        URL.revokeObjectURL(res);
        resolve(true);
      }, error => {
        reject(error);
      }); 
    });
  }



  /*Use to Delete user with user id */
  delete(userid) {
    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + '/user/' + userid;
    return this._http.delete(url, { headers });
  }

  loader(key) {
    if (key === 'show') { this.spinner.show(); }
    if (key === 'hide') { this.spinner.hide(); }
  }
  post(url: string, data) {
    const path = this._host + url;
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(path, data, { headers });
  }
  requestUrl(route, api, data) {
    const path = `${this._host}/${route}/${api}`;
    const headers = this._commanService.getAuthorizationHeader();
    console.log('pathURl', path);
    // return this._http.post(path, data, { headers });
    return this._http.post(path, data, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error.error.message);
  }
}
