import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule} from "angular2-datatable";
import { FormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { ImageUploadModule } from 'ng2-imageupload';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { SharedModule } from '../shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';

 
@NgModule({
  imports: [
    DashboardRoutingModule,
    CommonModule,
    DataTableModule,
    FlashMessagesModule,
    SharedModule,
    ChartsModule,
    ImageUploadModule,
    FormsModule
  ],
  providers: [ThemeService],
  declarations: [DashboardComponent],
  entryComponents: []
})
export class DashboardModule { }
