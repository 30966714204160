import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { CommanService } from '../../shared/services/comman.service';
import { DialogService } from 'ng2-bootstrap-modal';
import { tsConstants } from '../../tsconstant';
import { environment } from '../../../environments/environment';
import { CacheService } from 'src/app/shared/services/cache.service';
import { tsMessages } from '../../tsmessage';

@Component({
  templateUrl: 'view-user.component.html',
  styleUrls: ['view-user.component.scss'],
})
export class ViewUserComponent implements OnInit {
  get username() { return this.user ? (this.user.firstName + ' ' + this.user.lastName).trim() : null; }
  get qTypeOfProvider() { return this._cacheService.questionnaire ? this._cacheService.questionnaire.typeOfProvider : null; }
  get categories() { return this._cacheService.categories; }

  isServiceIdSelected(id: string) {
    return this.user && this.user.services && this.user.services.findIndex((_id: string) => id == _id) >= 0;
  }
  private _host = tsConstants.HOST;

  public userID = '';
  public user: any = {};
  public FRONTEND_URL = environment.config.FRONTEND_URL;
  public certificate: { _id: string, title: string, description: string, certificateFiles: string[] };
  public certificateFiles: { filepath: string, filename: string, original: string, filetype: string }[];
  public questionnaire: any;

  public s3 = environment.config.AWS_S3;

  private AWS_S3: string;
  private role: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _commanService: CommanService,
    private _cacheService: CacheService,
    private _dialogService: DialogService) {

    this.userID = _route.snapshot.params.id;

  }

  ngOnInit(): void {
    this.AWS_S3 = environment.config.AWS_S3;

    this._route.queryParams.subscribe(data => {
      this.role = data.role;
      this.getUserDetails();
      this.getUserBadges();
    });
  }

  getUserBadges() {

  }

  getUserDetails() {
    this._userService.loader('show');
    const request = this._userService.get(this.userID);
    request.subscribe((res: any) => {
      this._userService.loader('hide');

      if (res.statusCode === 200) {
        this.user = res.data;
        this._userService.getCertificates(this.userID).subscribe((res1: any) => {
          this.certificateFiles = [];
          if (res1.statusCode === 200) {
            this.certificate = res1.data[0];
            if (this.certificate && this.certificate.certificateFiles) {

              res1.data[0].certificateFiles.forEach((f: string) => {
                const array = f.split('/');
                this.certificateFiles.push({
                  original: f,
                  filepath: this.AWS_S3 + f,
                  filename: array[array.length - 1].replace(/([0-9a-zA-Z]+\-)?/, ''),
                  filetype: (f.match(/jpe?g|png/)) ? 'image' : 'file',
                });
              });
            }

          }
        }, error => {
          console.log(error);
        });
      } else {
        // this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this._userService.loader('hide');
    });
  }

  changeStatus() {
    const status = !this.user.status;
    const message = status ? tsMessages.DO_YOU_WANT_TO_ACTIVATE_USER : tsMessages.DO_YOU_WANT_TO_DEACTIVATE_USER;

    if (confirm(message)) {
      this._userService.loader('show');
      this._userService.changeStatus(this.user._id, status).subscribe((res: any) => {
        this._userService.loader('hide');
        if (res.statusCode === 200) {
          this._commanService.showAlert(res.data.message, 'alert-success');
          this.user.status = status;
        } else {
          this._commanService.showAlert(res.error.message, 'alert-danger');
        }
      }, error => {
        this._userService.loader('hide');
        this._commanService.showAlert(error.message, 'alert-danger');
      });
    }
  }



  async downloadFile(data: { filepath: string, filename: string, original: string, filetype: string }) {
    try { const result = await this._userService.downloadFile(data.original, data.filename); } catch (error) { console.log(error); }
  }

  toggleApprove(approved) {
    this._userService.loader('show');
    this._userService.updateBadge(this.userID, 'isApproved', approved).subscribe((res: any) => {
      // this.getUserDetails();
      this.user = res.data;
      this._userService.loader('hide');
    }, error => {
      console.log(error);
      this._userService.loader('hide');
    });
  }

  toggleVerifiedBadge(verified) {
    this._userService.loader('show');
    this._userService.updateBadge(this.userID, 'verifiedBadge', verified).subscribe((res: any) => {
      // this.getUserDetails();
      this.user = res.data;

      this._userService.loader('hide');
    }, error => {
      console.log(error);
      this._userService.loader('hide');
    });
  }
}
