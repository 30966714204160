import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFormsModule } from 'ng2-validation';
import { SharedModule } from '../shared/shared.module';
import { DataTableModule } from 'ng-angular8-datatable';
import { ImageUploadModule } from 'ng2-imageupload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CKEditorModule } from 'ng2-ckeditor';
import { CmsRoutingModule } from './cms-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { ListCmsComponent } from './list-cms/list-cms.component';
import { AddCmsComponent } from './add-cms/add-cms.component';
import { ViewCmsComponent } from './view-cms/view-cms.component';



@NgModule({
  declarations: [ListCmsComponent, AddCmsComponent, ViewCmsComponent],
  imports: [
    CommonModule,
    CmsRoutingModule,
    NgxSpinnerModule,
    DataTableModule,
    CustomFormsModule,
    ModalModule,
    FlashMessagesModule,
    SharedModule,
    ImageUploadModule,
    CKEditorModule

  ]
})
export class CmsModule { }
