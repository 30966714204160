import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListBlogsComponent } from './list-component/list-blogs.component';
import { AddUpdateBlogsComponent } from './addupdate-component/addupdate-blogs.component';
import { ViewBlogsComponent } from './view-component/view-blogs.component';
import { BlogsRoutingModule } from './blogs-routing.module';
import { CustomFormsModule } from 'ng2-validation';
import { SharedModule } from '../shared/shared.module';
import { ImageUploadModule } from 'ng2-imageupload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BlogsService } from '../blogs/services/blogs.service';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTableModule } from 'ng-angular8-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule, PaginationConfig } from 'ngx-bootstrap/pagination';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ListBlogsByUserComponent } from './list-blogs-by-user/list-blogs-by-user.component';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';
@NgModule({
  imports: [
    BlogsRoutingModule,
    CommonModule,
    CustomFormsModule,
    SharedModule,
    ImageUploadModule,
    ModalModule,
    PaginationModule,
    FlashMessagesModule,
    CKEditorModule,
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DataTableModule,
    FormsModule, ReactiveFormsModule,
    QuillModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    ListBlogsComponent,
    AddUpdateBlogsComponent,
    ViewBlogsComponent,
    ListBlogsByUserComponent
  ],
  providers: [
    BlogsService,
    PaginationConfig,
  ],
})
export class BlogsModule { }
