import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { CommanService } from '../../shared/services/comman.service';
import { FullLayoutService } from '../../layouts/full-layout.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'field-officer-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss']
})
export class SubscriberComponent implements OnInit {
  private _SubcriberObservable: any;
  public subscriberForm: FormGroup;
  submitted = false;
  subscribers: any = [];
  emails: any = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: any = {};
  subscriberText: any;
  subscriberId: any;
  selectedDataArray = [];
  selectedUserDataArray = [];
  public allMasterSelect = false;
  public allMasterUserSelect = false;
  selectedData: any;
  selectedSubData: string;
  userTypeItems: any = [];
  showDropdownEmail: boolean = true;
  dropdownUserTypeList: any = [];
  selectedUserData: any;
  roles: any = [];
  sendToAll: boolean = false;

  constructor(
    private _fullLayoutService: FullLayoutService,
    private _commanService: CommanService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    this.createForm()
  }

  createForm() {
    this.subscriberForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    },

    );
  }

  get f() { return this.subscriberForm.controls; }

  ngOnInit() {
    if (this.userTypeItems.length > 0) {
      this.showDropdownEmail = false;
    } else {
      this.showDropdownEmail = true;
    }

    this.dropdownUserTypeList = [
      { item_id: 'SP', item_text: 'Service Provider' },
      { item_id: 'U', item_text: 'User' },
      { item_id: 'C', item_text: 'Center' },
      { item_id: 'O', item_text: 'Others' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  changeUserType() {
    this.showDropdownEmail = false;
    let tmp_arr = [];
    var data = {
      'role': [this.roles]
    }
    this._SubcriberObservable = this._commanService.getAllSubscriber(data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.subscribers = res.data.data;
        this.subscribers.forEach((element) => {
          tmp_arr.push({ item_id: element._id, item_text: element.email });
        });
        this.dropdownList = tmp_arr;
      } else {
        this.toastr.error('No subscriber found');
      }
    },
      error => {
        this.toastr.error(error);
      }
    )
  }

  onItemSelect(item: any) {
    this.selectedData = item.item_text;
    this.selectedSubData = '';
    this.selectedDataArray.length = 0;

  }

  onSelectAll(items: any) {
    this.selectedDataArray = items
    this.allMasterSelect = true;

    this.selectedDataArray.forEach(function (items) {
      var x = items.item_text;
    });
    this.selectedItems.length = 0;
    this.sendToAll = true;
  }

  onSelectUser(item: any) {
    this.selectedUserData = item.item_text;
    this.selectedSubData = '';
    this.selectedUserDataArray.length = 0;
    this.roles.push(item.item_id);
    this.changeUserType();
  }

  onSelectAllUser(items: any) {
    this.selectedUserDataArray = items
    this.allMasterUserSelect = true;

    this.selectedUserDataArray.forEach(function (items) {
      var x = items.item_text;
    });
    this.userTypeItems.length = 0;
    this.selectedUserDataArray.forEach((element) => {
      this.roles.push(element.item_id);
    });
    this.changeUserType();
  }

  removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  onItemDeSelect(e) {
    this.removeElement(this.roles, e.item_id);
    if (this.roles.length == 0) {
      this.showDropdownEmail = true;
    } else {
      this.changeUserType();
    }
  }

  onItemDeSelectAll(e) {
    this.roles.length = 0;
    this.showDropdownEmail = true;
  }

  onEmailDeSelectAll(e) {
    this.sendToAll = false;
    this.emails.length = 0;
    this.selectedDataArray.length = 0;
    console.log(this.emails);
  }

  onSubmit() {
    this.submitted = true;
    if (!this.subscriberForm.invalid) {
      if (this.selectedDataArray.length > 0) {
        this.selectedDataArray.forEach(key => {
          this.emails.push(key.item_id);
        });
      } else {
        this.selectedItems.forEach(key => {
          this.emails.push(key.item_id);
        });
      }

      if (this.emails.length > 0) {
        var data = {
          'sendToall': this.sendToAll,
          'subject': this.subscriberForm.value.subject,
          'email': this.emails,
          'message': this.subscriberForm.value.message
        }
        this.spinner.show();
        this._SubcriberObservable = this._commanService.sendEmailSubscribers(data).subscribe((res:any)=>{
          this.spinner.hide();
            if(res.statusCode == 200){
              this.toastr.success(res.message);
              this.subscriberForm.reset();
              this.selectedDataArray = [];
              this.selectedItems = [];
              this.submitted = false;
              this.userTypeItems = [];
              this.sendToAll = false;
            }else{
              this.toastr.error(res.error.message);
            }
          },
          error=>{
            this.spinner.hide();
            this.toastr.error(error);
          }
          )
      } else {
        this.toastr.warning('Please select from emails list!');
      }

    }

  }

  ngOnDestroy() {
    if (this._SubcriberObservable) {
      this._SubcriberObservable.unsubscribe();
    }

  }
}
