export const environment = {
  production: true,
  config: {
    FRONTEND_URL: 'https://prompthealth.ca',
    APP_NAME: 'Wellness',
    API_URL: 'https://ocean.prompthealth.ca/api/v1',
    BASE_URL: 'https://ocean.prompthealth.ca/api/v1',
    AWS_S3: 'https://prompt-images.s3.us-east-2.amazonaws.com/',

    idSA: '5edb61483cd45aa28f6413c0',

  },
};
