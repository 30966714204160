import { Component, OnInit } from "@angular/core";
import { CommanService } from "../../shared/services/comman.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { tsMessages } from "../../tsmessage";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  public email1;

  public errMessage = "";
  public successMessage = "";
  public isPageLoading = false;

  constructor(
    private _router: Router,
    private _commanService: CommanService,
    private toastr: ToastrService
  ) { }

  ngOnInit() { }

  submit() {
    let data = { email: this.email1 };
    this._commanService.loader('show');
    this._commanService.forgotPsw(data).subscribe(
      (res: any) => {
        this._commanService.loader('hide');
        if (res.success) {
          this.toastr.success(res.data.message);
          this._router.navigate(["/login"]);
        } else {
          this.errMessage = res.error.message;
        }
      },
      err => {
        this.isPageLoading = false;
        this.errMessage = tsMessages.NO_SUCH_USER_EXIST;
      }
    );
  }
}
