import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommanService } from '../shared/services/comman.service';
import { FullLayoutService } from './full-layout.service';
import { CookieService } from 'ngx-cookie';

@Component({
    selector: 'app-dashboard',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.css'],
    providers: [CommanService]
})

export class FullLayoutComponent implements OnInit {

    public disabled: boolean = false;
    // public status: {isopen: boolean}   = {isopen: false};

    public active;
    public access = {
        users: false,
        category: false,
        roles: false,
        blogs: false,
    }
    public connection;
    public notifications = [];
    public messages: any;
    public notificationCount: any
    public myID = '';
    public loggedinUser = '';
    public chatID = '';
    public adminType = '';
    public defaultDashboard = '/dashboard';
    public acessDashboard = [];

    constructor(
        private _fullLayoutService: FullLayoutService,
        private _router: Router,
        private _activateRouter: ActivatedRoute,
        private _cookieService: CookieService,
        private _commanService: CommanService
    ) {

        this._fullLayoutService.setMarket(null);

        this.active = this._activateRouter.snapshot["_urlSegment"].segments[0].path;
        let actions = this._commanService.getActions();

        // this.adminType = actions["type"];
        // if (actions["type"] == 'SA') {
        //     for (var key in this.access) {
        //         if (this.access.hasOwnProperty(key)) {
        //             this.access[key] = true;
        //         }
        //     }
        // } else {
        //     this.access = {
        //         users: actions['users'].view,
        //         category: actions['category'].view,
        //         roles: actions['adminRoles'].view,
        //         blogs: false
        //     }
        // }

    }

    public toggled(open: boolean): void {
        // console.log('Dropdown is now: ', open);
    }

    ngOnInit(): void {

        this._commanService.getSessionUser().subscribe((res:any) => {
            if (res) {
                 this.myID = res.id;
                 this.loggedinUser = res.firstName;
            }
        });
    }


    logout() {
        this._cookieService.removeAll();
        this._router.navigate(['/login']);
    }

}
