import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SubscriptionPlanService } from '../services/subscription-plan.service';
import { CommanService } from '../../shared/services/comman.service';
import { CookieService } from 'ngx-cookie';
// import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { FlashMessagesService } from 'ngx-flash-messages';
import { tsMessages } from '../../tsmessage';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

declare let jsPDF;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  centreYearly = true;
  serviceYearly = true;
  isLoading = true;
  isPageLoading = false;
  public isPlanFetched = false;
  public basicPlans = {
    ListAmenities: false,
    ListOfProviders: false,
    ListProductsOption: false,
    ListYourTypeOfCenterAndDifferentLocations: false,
    addressURLLogoPicture: false,
    affiliatePlan: false,
    isDeleted: false,
    isMonthly: false,
    name: '',
    performanceDashboard: false,
    price: 0,
    professionalProfile: false,
    receiveMessagesForBooking: false,
    reviewsAndFeedback: false,
    status: false,
    userType: [],
    videoUpload: false,
    sideBySideComparisons: false,
    yearlyPrice: 0,
    _id: ''
  };
  public centrePlans = {
    ListAmenities: false,
    ListOfProviders: false,
    ListProductsOption: false,
    ListYourTypeOfCenterAndDifferentLocations: false,
    addressURLLogoPicture: false,
    affiliatePlan: false,
    isDeleted: false,
    isMonthly: false,
    name: '',
    performanceDashboard: false,
    price: 0,
    usPice: 0,
    professionalProfile: false,
    receiveMessagesForBooking: false,
    reviewsAndFeedback: false,
    status: false,
    userType: [],
    videoUpload: false,
    sideBySideComparisons: false,
    yearlyPrice: 0,
    usYearlyPrice: 0,
    _id: ''
  };
  public serviceProviderPlans = {
    ListAmenities: false,
    ListOfProviders: false,
    ListProductsOption: false,
    ListYourTypeOfCenterAndDifferentLocations: false,
    addressURLLogoPicture: false,
    affiliatePlan: false,
    isDeleted: false,
    isMonthly: false,
    name: '',
    performanceDashboard: false,
    price: 0,
    usPrice: 0,
    professionalProfile: false,
    receiveMessagesForBooking: false,
    reviewsAndFeedback: false,
    status: false,
    userType: [],
    videoUpload: false,
    sideBySideComparisons: false,
    yearlyPrice: 0,
    usYearlyPrice: 0,
    _id: ''
  };


  public partnerBasicPlan: PlanData = null;
  public partnerEnterprisePlan: PlanData = null;
  public response: any;
  public addOnPlans: any[] = [];

  public userTypeOptions = [
    'SP',
    'C',
    'P',
    'U'
  ];

  public newAddonPlan = {
    name: '',
    description: '',
    price: 0,
    yearlyPrice: 0,
    userType: []
  };
  public constructor(
    private _router: Router,
    private subscriptionService: SubscriptionPlanService,
    private _cookieService: CookieService,
    private _flashMessagesService: FlashMessagesService,
    private _commanService: CommanService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPlans();
    this.getAddonPlans();
    console.log(this.serviceProviderPlans);
    console.log(this.centrePlans);
  }

  /*Get all Plans */
  getPlans() {
    this.subscriptionService.loader('show');
    this.subscriptionService.getAllPlan('').subscribe((res: any) => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        console.log('res', res);
        res.data.forEach(element => {
          if (element.name === 'Basic') {
            this.basicPlans = element;
          }
          if (element.name === 'Premium' && element.userType[0] === 'C' && element.affiliatePlan === false) {
            this.centrePlans = element;
          }
          if (element.name === 'Premium' && element.userType[0] === 'SP' && element.affiliatePlan === false) {
            this.serviceProviderPlans = element;
          }
          if (element.name === 'Partner Basic' && element.userType[0] === 'P' && element.affiliatePlan === false) {
            this.partnerBasicPlan = new PlanData(element);
          }
          if (element.name === 'Partner Enterprise' && element.userType[0] === 'P' && element.affiliatePlan === false) {
            this.partnerEnterprisePlan = new PlanData(element);
          }
          this.isPlanFetched = true;
        });
        this.showAlert();
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      this._commanService.checkAccessToken(err);
    });

  }

  getAddonPlans() {
    this.subscriptionService.loader('show');
    this.subscriptionService.getAllAddonPlans().subscribe((res: any) => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        this.addOnPlans = res.data;
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      this._commanService.checkAccessToken(err);
    });

  }

  updateAddonPlan(index) {
    this.subscriptionService.loader('show');
    console.log(this.addOnPlans[index]);
    this.subscriptionService.updateAddOn(this.addOnPlans[index]).subscribe((res: any) => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        // this.addOnPlans = ;
        console.log('addons', this.addOnPlans);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      this._commanService.checkAccessToken(err);
    });
  }
  showAlert(): void {

    const alertMessage = this._cookieService.get('categoryAlert');
    if (alertMessage) {
      this._flashMessagesService.show(alertMessage, {
        classes: ['alert', 'alert-success'],
        timeout: 3000,
      });
      this._cookieService.remove('categoryAlert');
    }
  }
  updatePlan(planType) {
    console.log('planType', planType);
    this.subscriptionService.update(planType).subscribe((res: any) => {
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        console.log('res', res, res.message);
        this.getPlans();

        this.toastr.success(res.message);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error(err.messages, 'Error');
      this.subscriptionService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      this._commanService.checkAccessToken(err);
    });
  }

  createDefaultPlan(type: 'partnerEnterprise' | 'partnerBasic') {
    this._commanService.loader('show');
    this.subscriptionService.create(type).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        console.log(res);
        switch (type) {
          case 'partnerBasic':
            this.partnerBasicPlan = new PlanData(res.data);
            break;
          case 'partnerEnterprise':
            this.partnerEnterprisePlan = new PlanData(res.data);
            break;
        }

        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
        this._commanService.checkAccessToken(res);
      }
    }, error => {
      this._commanService.loader('hide');
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  createAddOn() {
    console.log(this.newAddonPlan);
    if (!this.newAddonPlan.name || !this.newAddonPlan.description) {
    }
    this.subscriptionService.createAddOn(this.newAddonPlan).toPromise().then((res: any) => {
      if (res.statusCode === 200) {
        this.getPlans();

        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
        this._commanService.checkAccessToken(res);
      }
    }, error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }
}

class PlanData {
  public _id: string;
  public name: string;
  public price: number;
  public yearlyPrice: number;
  public usPrice: number;
  public usYearlyPrice: number;
  public userType: string[];
  public status: boolean;
  public isDeleted: boolean;
  public isMonthly: boolean;
  public affiliatePlan: boolean;

  public ListAmenities: boolean;
  public ListOfProviders: boolean;
  public ListProductsOption: boolean;
  public ListYourTypeOfCenterAndDifferentLocations: boolean;
  public addressURLLogoPicture: boolean;
  public performanceDashboard: boolean;
  public professionalProfile: boolean;
  public receiveMessagesForBooking: boolean;
  public reviewsAndFeedback: boolean;
  public videoUpload: boolean;
  public sideBySideComparisons: boolean;
  public socialMediaLink: boolean;
  public verifiedBadge: boolean;
  public featuredOnHomePage: boolean;

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.name = data.name || '';
    this.price = data.price || 0;
    this.yearlyPrice = data.yearlyPrice || 0;
    this.usPrice = data.usPrice || 0;
    this.usYearlyPrice = data.usYearlyPrice || 0;
    this.userType = data.userType || [];
    this.status = data.status || false; /** why false? */
    this.isDeleted = data.isDeleted || false;
    this.isMonthly = data.isMonthly || false;
    this.affiliatePlan = data.affiliatePlan || false;
    this.ListAmenities = data.ListAmenities || false;
    this.ListOfProviders = data.ListOfProviders || false;
    this.ListProductsOption = data.ListProductsOption || false;
    this.ListYourTypeOfCenterAndDifferentLocations = data.ListYourTypeOfCenterAndDifferentLocations || false;
    this.addressURLLogoPicture = data.addressURLLogoPicture || false;
    this.performanceDashboard = data.performanceDashboard || false;
    this.professionalProfile = data.professionalProfile || false;
    this.receiveMessagesForBooking = data.receiveMessagesForBooking || false;
    this.reviewsAndFeedback = data.reviewsAndFeedback || false;
    this.videoUpload = data.videoUpload || false;
    this.sideBySideComparisons = data.sideBySideComparisons || false;
    this.socialMediaLink = data.socialMediaLink || false;
    this.verifiedBadge = data.verifiedBadge || false;
    this.featuredOnHomePage = data.featuredOnHomePage || false;
  }
}
