import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// Layouts
import { ThemeModule } from './theme/theme.module';
import { ReviewRatingComponent } from './review-rating/review-rating.component';

export const routes: Routes = [
  
  {
    path: '',
    // loadChildren: 'app/theme/theme.module#ThemeModule',
    loadChildren:() => ThemeModule
  }
];


@NgModule({
  imports: [ RouterModule.forRoot(routes),HttpClientModule ],
  exports: [ RouterModule,HttpClientModule ]
})
export class AppRoutingModule { }
