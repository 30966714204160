import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CommanService } from '../../shared/services/comman.service';
import { tsConstants } from '../../tsconstant';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private _host = tsConstants.HOST;

  constructor(
    private _http: HttpClient,
    private _commanService: CommanService,
    private spinner: NgxSpinnerService, ) {
  }

  /*Use to get all Users*/
  getAllUsers(rowsOnPage, activePage, sortTrem, search = '', roles = 'U') {
    let date = new Date().getTime().toString();
    let url = this._host + '/allstatic_pages?count=' + rowsOnPage + '&page=' + activePage + '&sortBy=' + sortTrem + '&roles=' + roles + '&search=' + search + '&date=' + date;

    let headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  /*Use to add new user*/
  add(user) {

    let headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/user', user, { headers });
  }

  put(body) {
    let headers = this._commanService.getAuthorizationHeader();
    return this._http.put(this._host + '/updatepages', body, { headers });
  }

  /*User to get user detail with ID*/
  get(userid) {

    let headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/Pages/' + userid, { headers });
  }

  /*Use to update user detail with there ID*/
  update(user) {

    let headers = this._commanService.getAuthorizationHeader();
    return this._http.put(this._host + '/user/' + user.id, user, { headers });
  }

  /*Use to fetch all States*/
  getStates() {

    let headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/states?sort=stateName', { headers });
  }

  /*Use to Delete user with user id */
  delete(userid, model) {

    let headers = this._commanService.getAuthorizationHeader();
    // let url = this._host + '/pages/' + userid + model;
    let url = this._host + '/delete?id=' + userid + '&model=' + model;
    // alert(url);
    return this._http.delete(url, { headers });
  }

  loader(key) {
    if (key == 'show') this.spinner.show();
    if (key == 'hide') this.spinner.hide();
  }

}
