import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';
import { CommanService } from '../../shared/services/comman.service';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie';
import { FlashMessagesService } from 'ngx-flash-messages';

// import tsMessages  = require('../../tsmessage');
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-cms',
  templateUrl: './add-cms.component.html',
  styleUrls: ['./add-cms.component.scss']
})
export class AddCmsComponent implements OnInit {
  public news = {
    title: '',
    description: ''
  };

  public ID = '';
  public category = { type: '' };

  public isLoading = false;
  public isPageLoading = true;
  public descriptionError = false;
  public titleError = false;
  public subtitleError = false;
  public errMessage = '';
  public newsID: any;

  constructor(
    private _router: Router,
    private _activatedRouter: ActivatedRoute,
    private _activateRouter: ActivatedRoute,
    private _categoryService: CmsService,
    private _cookieService: CookieService,
    private _commanService: CommanService,
    private _flashMessagesService: FlashMessagesService,
    private toastr: ToastrService
  ) {

    this.ID = _activatedRouter.snapshot.params.id;

    if (this.ID) {
      this._categoryService.loader('show');
      this._categoryService.get(this.ID).subscribe((res: any) => {
        this._categoryService.loader('hide');
        if (res.success) {
          this.news = res.data;
          console.log(this.category, 'this.category>>>>>>');
        } else {
          this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this._categoryService.loader('hide');
        this._commanService.checkAccessToken(err);
      });
    }

  }

  ngOnInit() {
  }

  save() {

    this._categoryService.loader('show');
    const data = JSON.parse(JSON.stringify(this.news));
    console.log('>>>>>>>>>>>>>>>>', data);
    this._categoryService.put(data).subscribe((res: any) => {
      this._categoryService.loader('hide');
      if (res.success) {

        this.toastr.success(res.message);
        this._router.navigate(['/cms/list']);
      } else {
        this.toastr.error(res.message);
        this.showDangerAlert();
      }
    }, err => {

    });
  }

  showDangerAlert(): void {

    const alertMessage = this._cookieService.get('categoryExistAlert');
    if (alertMessage) {
      this._flashMessagesService.show(alertMessage, {
        classes: ['alert', 'alert-danger'],
        timeout: 3000,
      });
      this._cookieService.remove('categoryExistAlert');
    }
  }

  trim(key) {
    if (this.news[key] && this.news[key][0] == ' ') { this.news[key] = this.news[key].trim(); }
  }

}
