import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Daterangepicker } from 'ng2-daterangepicker';

import { CommanService } from './services/comman.service';
import { LoaderComponent } from './loader/loader.component';

import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';

import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    Daterangepicker
  ],
  providers: [CommanService],
  declarations: [
    LoaderComponent,
    CapitalizePipe,
    SafeHtmlPipe,
    ProgressBarComponent
  ],
  exports: [
    LoaderComponent,
    CapitalizePipe,
    SafeHtmlPipe,
    ProgressBarComponent,
  ]
})
export class SharedModule { }
