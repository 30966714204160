import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from '../layouts/full-layout.component';
import { LoginComponent } from '../auth/login/login.component';
import { ForgotPasswordComponent } from '../auth/forgot-password/forgot-password.component';
import { ActiveRouteGuard } from '../auth/services/activate-route-guard';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ProfileModule } from '../profile/profile.module';

import { UsersModule } from '../users/users.module';

import { SettingsModule } from '../settings/settings.module';

import { DeactiveRouteGuard } from '../auth/services/deactivate-route-guard';

import { from, Subscriber } from 'rxjs';
import { SubscriptionPlanModule } from '../subscription-plan/subscription-plan.module';
import { CmsModule } from '../cms/cms.module';
import { BlogsModule } from '../blogs/blogs.module';
import { ReviewRatingComponent } from '../review-rating/review-rating.component';
import { PaymentComponent } from '../payment/payment.component';
import { CategoryManagementModule } from '../category-management/category-management.module';
import { SubscriberModule } from '../subscriber/subscriber.module';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: 'login',
		component: LoginComponent,
		// canActivate: [ActiveRouteGuard]
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent,
		// canActivate: [ActiveRouteGuard]
	},
	{
		path: '',
		component: FullLayoutComponent,
		data: {
			title: ''  //Home
		},
		// canActivate: [DeactiveRouteGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => DashboardModule,
			},
			{
				path: 'subscriber',
				loadChildren: () => SubscriberModule,
			},
			{
				path: 'payment',
				// loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
				// loadChildren: () => ReviewRatingComponent,
				component: PaymentComponent,
			},
			{
				path: 'review/:id',
				// loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
				// loadChildren: () => ReviewRatingComponent,
				component: ReviewRatingComponent,
			},
			{
				path: 'profile',
				loadChildren: () => ProfileModule,
			},

			{
				path: 'users',
				loadChildren: () => UsersModule,
			},

			{
				path: 'settings',
				loadChildren: () => SettingsModule,
			},
			{
				path: 'subscriptionplan',
				loadChildren: () => SubscriptionPlanModule,
			},
			{
				path: 'allCategory',
				loadChildren: () => CategoryManagementModule,
			},
			{
				path: 'cms',
				loadChildren: () => CmsModule,
			},
			{
				path: 'blogs',
				loadChildren: () => BlogsModule,
			}
		]
	}

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ThemeRoutingModule { }
