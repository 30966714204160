import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionPlanService } from '../services/subscription-plan.service';
import { CookieService } from 'ngx-cookie';
import { CommanService } from '../../shared/services/comman.service';


@Component({
    selector: 'app-view-subscription',
    templateUrl: './view-subscription.component.html',
    styleUrls: ['./view-subscription.component.scss'],
    providers: [SubscriptionPlanService]
})
export class ViewSubscriptionComponent {

    public ID = '';
    public category = { type: '' };
    // public isLoading:boolean   = true;
    public addEditDelete: boolean = false;

    constructor(
        private _router: Router,
        private _activatedRouter: ActivatedRoute,
        private _categoryService: SubscriptionPlanService,
        private _cookieService: CookieService,
        private _commanService: CommanService) {

        this.ID = _activatedRouter.snapshot.params['id'];
        this._categoryService.loader('show');
        // if (this.ID) {

        //     this._categoryService.get(this.ID).subscribe((res: any) => {

        //         if (res.success) {
        //             this._categoryService.loader('hide');
        //             this.category = res.data;
        //             /*console.log(res.data.type);
        //             this.category.type = this.firstUpper(this.category.type);*/
        //         } else {
        //             this._commanService.checkAccessToken(res.error);
        //         }
        //     }, err => {
        //         this._categoryService.loader('hide');
        //         this._commanService.checkAccessToken(err);
        //     });
        // }

    }

    edit(ID) {
        let route = '/subscriptionplan/edit/' + ID;
        this._router.navigate([route]);
    }


}