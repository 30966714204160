import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommanService } from 'src/app/shared/services/comman.service';
import { environment } from 'src/environments/environment';
import { BlogsService } from '../services/blogs.service';

@Component({
  selector: 'app-list-blogs-by-user',
  templateUrl: './list-blogs-by-user.component.html',
  styleUrls: ['./list-blogs-by-user.component.scss']
})
export class ListBlogsByUserComponent implements OnInit {

  constructor(
    private _blogService: BlogsService,
    private _commanService: CommanService,
  ) { }

  public filter: FormControl;
  
  public data: any[];
  public itemsTotal: number = 0;
  public countPerPage: number = 15;

  // public orderBy = 'createdAt'
  // public order = 'desc';

  public AWS_S3: string = environment.config.AWS_S3;


  async ngOnInit() {
    this.filter = new FormControl('not-approved');
    this.filter.valueChanges.subscribe(this.onChangeFilter);

    try {
      this._commanService.loader('show');
      await this.getBlogs();
    } catch (err) {
      console.log(err)
    } finally {
      this._commanService.loader('hide');
    }
  }

  onChangeFilter(val: string) {
    
  }

  getBlogs(page: number = 1): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._blogService.getAllBlogsByUsers(this.countPerPage, page).subscribe((res: any) => {
        const data = [];
        res.data.data.forEach( d => {
          d.description = this.removeHTML(d.description);
          d.reviewURL = 'http://localhost:4200/magazines/_review/' + d._id;
          data.push(d);
        });

        this.data = data;
        this.itemsTotal = res.data.total;
        resolve(true);
      });
    });
  }

  removeHTML(html: string) {
    if (html) html = html.replace(/<\/?[^>]+(>|$)/g, "");
    if (html) html = html.replace(/&.*;/g, '');
    return html;
  }

  public async onChangePage(e: {page: number, itemsPerPage: number}) {
    this.countPerPage = e.itemsPerPage;

    this._commanService.loader('show');
    await this.getBlogs(e.page);
    this._commanService.loader('hide');
  }
}
