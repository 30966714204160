import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogsService } from '../services/blogs.service';
import { CommanService } from '../../shared/services/comman.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    templateUrl: 'view-blogs.component.html',
    providers: [BlogsService]
})
export class ViewBlogsComponent {

    // public _host = environment.config.HOST;

    public blogID = '';
    public blog: any = {};
    public addEditDelete: boolean = false;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _blogService: BlogsService,
        private toastr: ToastrService,
        private _commanService: CommanService) {

        let actions = this._commanService.getActions();
        if (actions["type"] == 'SA' || actions['blogs']['addEditDelete']) this.addEditDelete = true;

        this.blogID = _route.snapshot.params['id'];
        this._commanService.loader('show');
        // // this._blogService.get(this.blogID).subscribe((res: any) => {
        //     this._commanService.loader('hide');
        //     if (res.success) {
        //         this.blog = res.data.blog;
        //     } else {
        //         this._commanService.checkAccessToken(res.error);
        //     }
        // }, err => {
        //     this.toastr.error('There are some errors, please try again after some time !', 'Error');
        // });

    }

    editBlog(ID) {
        let route = '/blogs/edit/' + ID;
        this._router.navigate([route]);
    }


}
