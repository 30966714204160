import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { Router } from '@angular/router';
import { CommanService } from '../../shared/services/comman.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie';

@Component({
    selector: 'app-changepassword',
    templateUrl: 'changepassword.component.html'
})
export class ChangePasswordComponent implements OnInit {

    public password = '';
    public newPassword = '';
    public confirmPassword = '';
    public errMessage = '';
    public isPageLoading = false;

    public user = {
        // id:'',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    constructor(private _router: Router,
        private _cookieService: CookieService,
        private _profileService: ProfileService,
        private _commanService: CommanService,
        private toastr: ToastrService
        ) { }

    ngOnInit() {
        this.isPageLoading = true;
        this.getCurrentUser();
    }

    submit() {
        this.isPageLoading = true;
        // let id = this.getCurrentUser()

        this.errMessage = '';
        this._commanService.loader('show');
        var obj ={
            currentpwd:this.user.currentPassword,
            password:this.user.newPassword,
            confirmPassword:this.user.confirmPassword
        }
        console.log(obj, 'obj')
        this._profileService.changePassword(obj).subscribe((res: any) => {
            this._commanService.loader('hide');
            console.log(res);
            if (res.statusCode==200) {
                this.toastr.success(res.message)
                this.logout();

            }else if (res.statusCode==400) {
                this.toastr.success(res.message)
            } else {
                this.toastr.error(res.error.message);

            }
        }, err => {
            this._commanService.loader('hide');
            this.toastr.error(err);
            

        });
    }

    getCurrentUser() {
        this.errMessage = '';
        this.isPageLoading = false;
        this._profileService.getcurrentuser().subscribe((res: any) => {
            this.user['id'] = res.id;
        });
    }

    logout() {
        this._cookieService.removeAll();
        this._router.navigate(['/login']);
    }

}
