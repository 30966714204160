import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CommanService } from '../../shared/services/comman.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie';
import { tsConstants } from '../../tsconstant';

@Injectable()
export class BlogsService {

  private _host = tsConstants.HOST;

  constructor(
    private _http: HttpClient,
    private _commonService: CommanService,
    private _cookieService: CookieService) {
  }

  makeHeadliner(blogID) {
    const headers = this._commonService.getAuthorizationHeader();
    const url = this._host + '/blog/headline/' + blogID;
    return this._http.post(url, null, { headers });
  }
  getAllBlogsByUsers(count: number, page: number,) {
    const url = `${this._host}/blog/get-all?count=${count}&page=${page}&frontend=0&categoryId=`;
    const headers = this._commonService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  getAllBlogs(rowsOnPage, activePage, sortTrem) {
    console.log('sortTrem', sortTrem);
    const url = `${this._host}/blog/get-all?count=${rowsOnPage}&page=${activePage}&frontend=0&categoryId=${sortTrem}`;

    const headers = this._commonService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  getAllContents() {
    const url = `${this._host}/note/filter/?count=1000`;
    return this._http.get(url);
  }

  allCatList(rowsOnPage, activePage, sortTrem, search = '') {
    const date = new Date().getTime().toString();
    // let url = this._host + '/allcategory?count=' + rowsOnPage + '&page=' + activePage + '&sortBy=' + sortTrem + '&search=' + search + '&date=' + date;

    const headers = this._commonService.getAuthorizationHeader();
    // return this._http.get(url, { headers });
  }

  /*Use to add new blogs*/
  add(body) {
    const headers = this._commonService.getAuthorizationHeader();
    return this._http.post(this._host + '/blog/create', body, { headers });
  }

  /*Use to get blogs with blog id*/
  get(blogID) {

    const headers = this._commonService.getAuthorizationHeader();
    return this._http.get(this._host + '/blog/get-by-id/' + blogID, { headers });
  }

  /*Use to update blogs*/
  update(blogs) {

    const headers = this._commonService.getAuthorizationHeader();
    return this._http.put(this._host + '/blog/' + blogs.id, blogs, { headers });
  }

  /*Use to update blogs*/
  edit(blogs, blogsId) {
    console.log('blogs===', blogs, blogsId);
    const headers = this._commonService.getAuthorizationHeader();
    return this._http.put(this._host + '/blog/update/' + blogsId, blogs, { headers });
  }

  /* Delete blogs from list */
  delete(blogID) {

    const headers = this._commonService.getAuthorizationHeader();
    const url = this._host + '/blog/delete/' + blogID;
    return this._http.delete(url, { headers });
  }
  /*Use to soft delete any Record */
  changeStatus(id: string, status: string) {
    const data = {
      id, status
    };
    const headers = this._commonService.getAuthorizationHeader();
    const url = this._host + '/blog/updateStatus';
    return this._http.put(url, data, { headers });
  }
  getUserID() {
    const userData: any = this._cookieService.getObject('userData');
    if (userData) {
      return userData.id;
    }
  }

}
