import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { CommanService } from '../../shared/services/comman.service';
import { tsMessages } from '../../tsmessage';
import { CacheService } from 'src/app/shared/services/cache.service';

@Component({
  selector: 'app-users',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

  get categories() { return this._cacheService.categories; }
  get categoriesFlatten() { return this._cacheService.categoriesFlatten; }
  get qTypeOfProvider() { return this._cacheService.questionnaire ? this._cacheService.questionnaire.typeOfProvider : null; }
  get pagination() {
    const pageMax = Math.ceil(this.itemsTotal / (this.itemsOnPage || this.itemsOnPageDefault));
    const array = [];
    for(let i=1; i<=pageMax; i++) {
      array.push(i);
    }
    return array;
  }

  usernameOf(user: any) {
    let name = (user.firstName + ' ' + user.lastName).trim();
    return name ? name : 'no name';
  }

  countMainCategoriesOf(user: any) {
    let count = 0;
    if(user.services){
      user.services.forEach(id => {
        if(this.categories && this.categories.findIndex(item => item._id == id) >= 0) {
          count ++;
        }
      });  
    }
    return count;
  }

  countTypeOfProviderOf(user: any) {
    let count = 0;
    if (user.services) {
      user.services.forEach(id => {
        if(this.qTypeOfProvider && this.qTypeOfProvider.answers.findIndex(item => item._id == id) >= 0) {
          count ++;
        }
      })
    }
    return count;
  }

  public listType = 'user';


  public data: any = [];
  public affData = [];
  public totalRecords = 0;
  public filterQuery = '';
  public rowsOnPage = 50;

  public affRowsOnPage = 50;
  public sortBy = '';
  public sortOrder = 'desc';
  public activePage = 1;
  public affActivePage = 1;
  public itemsTotal = 0;
  public affItemsTotal = 0;
  searchTerm = '';
  public sortTrem = '';

  public itemsOnPage;
  public itemsOnPageDefault = 50;

  public affItemsOnPage;

  public response: any;
  public isLoading = false;
  public isPageLoading = true;
  public addEditDelete = true;
  public roles = '';
  public exportData = [];
  public filterList = [
    { id: '', title: 'All' },
    { id: 'SP', title: 'Service Provide' },
    { id: 'C', title: 'Centre' },
    { id: 'P', title: 'Company'},
    { id: 'U', title: 'Client' },
  ];
  
  public constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _cacheService: CacheService,
    private _commanService: CommanService) {

    const actions = this._commanService.getActions();
    // if (actions["type"] == 'SA' || actions['users']['addEditDelete']) this.addEditDelete = true;
  }

  ngOnInit(): void {
    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    /*set initial sort condition */
    this.sortTrem = 'createdAt' + ' ' + this.sortOrder;

    /*Load data*/
    // this.getUsers();

    this._route.queryParams.subscribe(params => {
      this.roles = params.roles;
      this.searchTerm = params.keyword || '';
      this.listType = params.type || 'user';
      this.itemsOnPage = Number(params.count || this.itemsOnPageDefault);
      this.rowsOnPage = this.itemsOnPage;
      this.activePage = Number(params.page || 1);
      this.isFilterShown = false;

      if(this.listType == 'user') {
        this.getUsers();
      } else if(this.listType == 'affiliate') {
        this.getAffiliateUsers();  
      } else {
        console.log('this list type is not implemented: ', this.listType);
      }
    });
  }

  public toInt(num: string) {
    return +num;
  }

  public sortByWordLength = (a: any) => {
    return a.city.length;
  }

  viewUser(userID, roles: string) {
    // console.log(userID);
    const route = '/users/list/' + userID;
    this._router.navigate([route], {queryParams: {role: roles}}).then(res => {
    }).catch((error) => {
      console.error(error);
    });
  }

  editUser(userID) {
    const route = '/review';
    const id = userID;
    this._router.navigate([route, id]);
  }

  changeStatus(user) {
    let status;
    let message = tsMessages.DO_YOU_WANT_TO_ACTIVATE_USER;
    if (user.status === true) {
      status = false;
      message = tsMessages.DO_YOU_WANT_TO_DEACTIVATE_USER;
    } else {
      status = true;
    }

    if (confirm(message)) {
      this.isLoading = true;
      this._userService.changeStatus(user._id, status).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.response = res;
          const start = (this.activePage * this.rowsOnPage - this.rowsOnPage + 1);
          this.itemsTotal = this.itemsTotal - 1;

          if (!(this.itemsTotal >= start)) {
            this.activePage = this.activePage - 1;
          }
          this._commanService.showAlert(res.data.message, 'alert-success');
          /* reload page. */
          this.getUsers();
        } else {
          this.isLoading = false;
          this._commanService.showAlert(res.error.message, 'alert-danger');
          // this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }


  /*Get all Users */
  getUsers(): void {
    this._userService.loader('show');
    if (this.roles === 'all') { this.roles = ''; }

    let sortBy: string;
    switch (this.sortBy) {
      case 'role': sortBy = 'roles'; break;
      case 'userType': sortBy = 'plan.price'; break;
      default: sortBy = 'createdAt';
    }

    this._userService.getAllUsers(this.roles, this.searchTerm, this.rowsOnPage.toString(), this.activePage.toString(), sortBy, this.sortOrder).subscribe((res: any) => {
      this._userService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        this.data = res.data.data;
        this.itemsTotal = res.data.total;
      } else {
        // this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.isLoading = false;
      this.isPageLoading = false;
    });
  }
  /*Get all Users */
  getAffiliateUsers(): void {
    this._userService.loader('show');
    this._userService.getAllAffiliateUsers(this.affRowsOnPage, this.affActivePage, this.searchTerm).subscribe((res: any) => {
      this._userService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        this.data = res.data.data;
        this.itemsTotal = res.data.total;
      } else {
        // this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.isLoading = false;
      this.isPageLoading = false;
    });
  }
  approveVIP(user, active) {
    // console.log('user', user, active);
    const path = `/user/request-update`;
    const data = {
      id: user._id,
      adminApproved: active ? 'approved' : 'cancled'
    };
    // console.log('getAffiliateUsers', data);
    this._userService.loader('show');
    this._userService.post(path, data).subscribe((res: any) => {
      this._userService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.statusCode === 200) {
        this.getAffiliateUsers();
      }
    }, err => {
      this.isLoading = false;
      this.isPageLoading = false;
    });

  }

  public onListTypeChange(type) {
    let queryParams = this._route.snapshot.queryParams;
    queryParams = {
      ...queryParams,
      type: type,
    }

    this._router.navigate(['/users'], {queryParams: queryParams, replaceUrl: true});

  }

  public onPageChange(event) {
    let queryParams = this._route.snapshot.queryParams;
    queryParams = {
      ...queryParams,
      page: event,
    }
    this._router.navigate(['/users'], {queryParams: queryParams, replaceUrl: true})
  }

  public onRowsChange(event): void {
    this.rowsOnPage = this.itemsOnPage;
    let queryParams = this._route.snapshot.queryParams;
    queryParams = {
      ...queryParams,
      count: this.itemsOnPage,
      page: 1,
    }
    this._router.navigate(['/users'], {queryParams: queryParams, replaceUrl: true})
  }

  affonRowsChangeAff(event): void {
    this.isLoading = true;
    this.affRowsOnPage = this.affItemsOnPage;
    this.affActivePage = 1;
    this.getAffiliateUsers();
  }

  public isFilterShown = false;
  public onClickFilter() {
    this.isFilterShown = !this.isFilterShown;
  }

  public onSortOrder(event) {
    this.sortTrem = this.sortBy + ' ' + this.sortOrder;
    this.isLoading = true;
    this.getUsers();
  }

  public onSortBy(by: string) {
    if (by == this.sortBy) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }
    this.sortBy = by;

    this.isLoading = true;
    this.getUsers();
  }

  public search(event, element = 'input') {
    if (element === 'input') {
      if (event.keyCode === 13 || this.searchTerm === '') {
        this.searchTerm = this.searchTerm.trim();

        let queryParams = this._route.snapshot.queryParams;
        queryParams = {
          ...queryParams,
          page: 1,
          keyword: this.searchTerm,
        }
        if(!this.searchTerm) {
          delete queryParams.keyword;
        }
        this._router.navigate(['users'], {queryParams: queryParams, replaceUrl: true});
      }
    } else {
      this.searchTerm = this.searchTerm.trim();

      let queryParams = this._route.snapshot.queryParams;
      queryParams = {
        ...queryParams,
        page: 1,
        keyword: this.searchTerm,
      }
      if(!this.searchTerm) {
        delete queryParams.keyword;
      }
      this._router.navigate(['users'], {queryParams: queryParams, replaceUrl: true});

    }
  }

  public onSelectFilterRole(e: Event, type) {
    e.stopPropagation();
    e.preventDefault();
    this.roles = type;
    let queryParams = this._route.snapshot.queryParams;
    queryParams = {
      ...queryParams,
      roles: this.roles,
    }
    if(!this.roles) {
      delete queryParams.roles;
    }

    this._router.navigate(['/users'], {queryParams: queryParams, replaceUrl: true});
    // this.getUsers();
    // this.getAffiliateUsers();
  }
  public userFilter(evt) {
    this._commanService.loader('show');
    if (evt.target.value === 'all') {
      this.sortTrem = '';
      this.getUsers();
      this.getAffiliateUsers();
    } else {
      // let language   =  this.lang;
      this.sortTrem = evt.target.value;
      this.getUsers();
      this.getAffiliateUsers();
    }
  }

  approveUser(e: Event, user) {
    e.stopPropagation();
    e.preventDefault();
    this._userService.loader('show');
    this._userService.updateBadge(user._id, 'isApproved', true).subscribe((res: any) => {
      // this.getUserDetails();
      user.isApproved = true;
      this._userService.loader('hide');
    }, error => {
      console.log(error);
      this._userService.loader('hide');
    });
  }
}
