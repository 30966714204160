import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FlashMessagesService } from 'ngx-flash-messages';
import { FullLayoutService } from '../../layouts/full-layout.service';
import { tsConstants } from '../../tsconstant';
import { tsMessages } from '../../tsmessage';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

// import { Angular2Csv } from 'angular2-csv/Angular2-csv';

declare let jsPDF;

@Injectable()
export class CommanService {

  private _host = tsConstants.HOST;
  rootUrl: string = environment.config.BASE_URL;
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _cookieService: CookieService,
    private spinner: NgxSpinnerService,
    private _flashMessagesService: FlashMessagesService,
    private _fullLayoutService: FullLayoutService,
    private _location: Location) {
  }

  /*This function is use to remove user session if Access token expired. */
  checkAccessToken(err): void {
    const code = err.code;
    const message = err.message;

    if ((code == 401 && message == 'authorization')) {
      this._cookieService.removeAll();
      this.showAlert('Session Expired.', 'alert-danger');
      this._router.navigate(['/login', { data: true }]);
    } else {

    }
  }

  /*This function is use to get access token from cookie. */
  getAccessToken(): string {
    const token = this._cookieService.get('token');
    return token;
  }

  /*This function is use to get header with Authorization or without Authorization. */
  getAuthorizationHeader() {

    const token = this.getAccessToken();
    let headers = {};

    if (token) {
      headers = new HttpHeaders()
        .append('Authorization', token);
    }
    return headers;
  }

  /*This function is use to get Roles from cookie. */
  getActions(): object {
    const actions = this._cookieService.getObject('actions');
    return actions;
  }

  login(body) {
    return this._http.post(this._host + '/oauth/admin', body);
  }
  imgUpload(body, path) {
    // let headers = this.getAuthorizationHeader();
    return this._http.post(this._host + path, body);
  }
  forgotPsw(body) {
    return this._http.post(this._host + '/user/forgotpassword', body);
  }

  get(path) {
    const headers = this.getAuthorizationHeader();
    return this._http.get(this._host + path, { headers });
  }

  post(body, path) {
    const headers = this.getAuthorizationHeader();
    return this._http.post(this._host + path, body, { headers });
  }

  uploadImage(object) {

    const headers = this.getAuthorizationHeader();
    return this._http.post(this._host + '/upload', object, { headers });
  }

  uploadCSV(object) {
    const headers = this.getAuthorizationHeader();
    return this._http.post(this._host + '/bulkUpload', object, { headers });
  }

  uploadProductCSV(object) {
    const headers = this.getAuthorizationHeader();
    return this._http.post(this._host + '/bulkProductUpload', object, { headers });
  }

  /*Use to fetch all categories*/
  getAllCategories() {

    const headers = this.getAuthorizationHeader();
    const url = this._host + '/categoryList';
    return this._http.get(url, { headers });
  }

  /*Use to fetch all Users*/
  getAllUsers(role, multipleroles = []) {
    let multipleRoles = '';
    if (!role && multipleroles.length) { multipleRoles = JSON.stringify(multipleroles); }

    const headers = this.getAuthorizationHeader();
    const url = this._host + '/activeuser?roles=' + role + '&multipleroles=' + multipleRoles;
    return this._http.get(url, { headers });
  }

  /*Use to fetch all States*/
  getStates() {

    const headers = this.getAuthorizationHeader();
    return this._http.get(this._host + '/states?sort=stateName', { headers });
  }

  /*Use to soft delete any Record */
  deleteRecord(id, model) {

    const headers = this.getAuthorizationHeader();
    const url = this._host + '/delete?id=' + id + '&model=' + model;
    return this._http.delete(url, { headers });
  }

  /*Use to get Settings */
  getSettings() {
    const headers = this.getAuthorizationHeader();
    return this._http.get(this._host + '/setting', { headers });
  }

  /*Use to soft delete any Record */
  changeStatus(id, status) {
    const data = {
      id, status
    };
    const headers = this.getAuthorizationHeader();
    const url = this._host + '/blog/updateStatus';
    return this._http.put(url, data, { headers });
  }

  /*Use to convert date to date object for date picker module */
  convertDateToDateObject(date) {
    let _date = new Date(date);
    if (_date) {
      const obj = { date: { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() }, jsdate: date };
      return obj;
    } else {
      return {};
    }
  }

  showAlert(message, alertClass) {
    if (message == null || typeof message == 'object') { message = tsMessages.SERVER_ERROR; }
    this._fullLayoutService.showAlert(message, alertClass, tsConstants.ALERT_TIME);
  }

  // Use to browse back based on location history when click on back buttton.
  back() {
    this._location.back();
  }

  getSessionUser() {
    const headers = this.getAuthorizationHeader();
    return this._http.get(this._host + '/user/current', { headers });
  }

  loader(key) {
    if (key == 'show') { this.spinner.show(); }
    if (key == 'hide') { this.spinner.hide(); }
  }

  toDateFormate(i, DATE) {

    let _date = DATE ? new Date(DATE) : null;
    let date = '-';
    if (_date) {
      date = _date ? _date.getDate() + '/' + (_date.getMonth() + 1) + '/' + _date.getFullYear() : '-';
    }

    return date;
  }

  allCities() {
    const headers = this.getAuthorizationHeader();
    return this._http.get(this._host + '/allcities', { headers });
  }

  allProvinces() {
    const headers = this.getAuthorizationHeader();
    return this._http.get(this._host + '/allprovinces', { headers });
  }

  // downloadCSV( name, filteredData ) {

  //     let fileName = name + "-" + Math.floor(Date.now() / 1000);
  //     new Angular2Csv( filteredData, fileName);

  // }

  downloadPDF(name, header, filteredData) {

    const doc = new jsPDF();
    const fileName = name + '\'-\'+ Math.floor(Date.now() / 1000';

    doc.autoTable(header, filteredData, {
      theme: 'grid',
      headerStyles: { fillColor: 0 },
      startY: 10, // false (indicates margin top value) or a number
      margin: { horizontal: 7 }, // a number, array or object
      pageBreak: 'auto', // 'auto', 'avoid' or 'always'
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
      tableHeight: '1', // 'auto', 'wrap' or a number,
      showHeader: 'everyPage',
      tableLineColor: 200, // number, array (see color section below)
      tableLineWidth: 0,
      fontSize: 10,
      overflow: 'linebreak',
      columnWidth: 'auto',
      cellPadding: 2,
      cellSpacing: 0,
      valign: 'top',
      lineHeight: 15,

    });

    doc.save(fileName);
  }
  deleteReview(id) {

    const headers = this.getAuthorizationHeader();
    const url = this._host + '/user/removeRating/' + id;
    return this._http.delete(url, { headers });
  }

  getAllSubscriber(data) {
    const headers = this.getAuthorizationHeader();
    const url = this.rootUrl + '/user/subscribe-get-all?role=' + data.role + '&count=10&page=1';
    return this._http.get(url, { headers });
  }

  sendEmailSubscribers(formData) {
    const headers = this.getAuthorizationHeader();
    return this._http.post(this.rootUrl + '/user/subscribe-send-mail', formData, { headers });
  }


  /// image controller
  async shrinkImageByFixedWidth(file: File | Blob, width: number = 1500): Promise<{ file: Blob, filename: string }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = (e: any) => {
        if(img.width > width) {
          const t = e.target;
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = img.height * width / img.width;
          const ctx = canvas.getContext('2d');
  
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((b: Blob) => {
            const filename = Date.now().toString() + '.' + b.type.replace('image/', '');
            resolve({ file: b, filename });
          }, file.type);  
        } else {
          resolve({file: file, filename: (file instanceof File) ? file.name : Date.now().toString()});
        }
      };
      img.src = URL.createObjectURL(file);
    });
  }

  b64ToBlob(data: string) {
    const regExContentType = /data:(image\/.+);base64/;
    const contentType = data.match(regExContentType)[1];

    const byteString = atob(data.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
  }
}

