import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ThemeRoutingModule } from './theme-routing.module';
import { FullLayoutComponent } from '../layouts/full-layout.component';
import { LoginComponent } from '../auth/login/login.component';
import { ForgotPasswordComponent } from '../auth/forgot-password/forgot-password.component';
import { SharedModule } from '../shared/shared.module';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarComponent } from '../layouts/sidebar/sidebar.component';
import { BreadcrumbsComponent } from '../shared/breadcrumb.component';
import { ReviewRatingComponent } from '../review-rating/review-rating.component';
import { PaymentComponent } from '../payment/payment.component';
import { DataTableModule } from 'ng-angular8-datatable';
// import { NgxCarouselModule } from 'ngx-carousel';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ThemeRoutingModule,
    // NgxCarouselModule,
    FlashMessagesModule,
    NgxSpinnerModule,
    DataTableModule,
  ],
  declarations: [
    FullLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    ReviewRatingComponent,
    PaymentComponent
  ]
  
})
export class ThemeModule { }
