import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CommanService } from '../shared/services/comman.service';

@Component({
  selector: 'app-review-rating',
  templateUrl: './review-rating.component.html',
  styleUrls: ['./review-rating.component.scss']
})
export class ReviewRatingComponent implements OnInit {

  data = [];
  itemsTotal = 0;
  activePage = 0;
  isLoading = true;
  isPageLoading = true;

  id;
  private sub: any;
  constructor(
    private route: ActivatedRoute,
    private _commanService: CommanService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number

    // In a real app: dispatch action to load the details here.
    });
    this.getUsersRating();
  }
  getUsersRating() {
    const path = `/user/get-all-ratings?userId=${this.id}&search=&count=${this.itemsTotal}&page=${this.activePage}`;
    this._commanService.get(path).subscribe((res: any) => {
        this._commanService.loader('hide');
        this.isLoading = false;
        this.isPageLoading = false;
        if (res.statusCode === 200) {
            this.data = res.data;
            console.log('res????', this.data)
            this.itemsTotal = res.data.total;
            if (res.data.users && res.data.users.length == 1) this.activePage = 1;
        } else {
            // this._commanService.checkAccessToken(res.error);
        }
    }, err => {
        this.isLoading = false;
        this.isPageLoading = false;
    });
  }
  deleteRating(id) {
    const userID = ''+id
    console.log('userID', userID)
    this._commanService.deleteReview(userID).subscribe((res: any) => {
      this._commanService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      console.log('res????', res)
      if (res.statusCode === 200) { // TODO : check res
          this.data = res.data;
          console.log('res????', this.data)
          this.toastr.success(res.message);
          this.getUsersRating()
      } else {
          // this._commanService.checkAccessToken(res.error);
      }
  }, err => {
      this.isLoading = false;
      this.isPageLoading = false;
      this.toastr.error(err.message);
  });
  }
}
