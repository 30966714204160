import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  public questionnaire: any;
  public categories: any;
  public categoriesFlatten: any;

  saveQuestionnaire(qs: any) {
    const data: any = {};
    qs.forEach((q: any) => {
      switch(q.slug){
        case 'who-are-your-customers': // sub answers not implemented
          data.customerHealth = q;
          break;
        case 'treatment-modalities':
          data.treatmentModality = q;
          break;
        case 'providers-are-you':
          data.typeOfProvider = q;
          break;
      }
    });
    this.questionnaire = data;
  }

  saveCategories(cats: any) {
    this.categories = cats;

    let flatten = [];
    if(this.categories) {
      for (let cat of this.categories) {
        flatten.push({_id: cat._id, item_text: cat.item_text});
        flatten = flatten.concat(cat.subCategory); 
      }
    }
    this.categoriesFlatten = flatten;
  }
}
