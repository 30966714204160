import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CmsService } from '../services/cms.service';
import { CommanService } from '../../shared/services/comman.service';
import { tsMessages } from '../../tsmessage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-list-cms',
  templateUrl: './list-cms.component.html',
  styleUrls: ['./list-cms.component.scss']
})
export class ListCmsComponent implements OnInit {

  public data = [];
  public totalRecords = 0;
  public filterQuery = '';
  public rowsOnPage = 5;
  public sortBy = '';
  public sortOrder = 'desc';
  public activePage = 1;
  public itemsTotal = 0;
  public searchTerm = '';
  public sortTrem = '';

  public itemsOnPage;

  public response: any;
  public isLoading = false;
  public isPageLoading = true;
  public addEditDelete = false;
  public roles = 'U';
  public exportData = [];

  public constructor(
    private _router: Router,
    private _cmsService: CmsService,
    private _commanService: CommanService,
    private toastr: ToastrService) {

    const actions: any = this._commanService.getActions();
    if (actions.type === 'SA' || actions.users.addEditDelete) { this.addEditDelete = true; }
  }

  ngOnInit(): void {

    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    /*set initial sort condition */
    this.sortTrem = 'createdAt' + ' ' + this.sortOrder;

    /*Load data*/
    this.getUsers();
    this.activePage = 1;
    this.getUsers();

    this.itemsOnPage = this.rowsOnPage;
  }

  public toInt(num: string) {
    return +num;
  }

  public sortByWordLength = (a: any) => {
    return a.city.length;
  }

  viewUser(userID) {
    const route = '/cms/list/' + userID;
    this._router.navigate([route]);
  }

  editUser(userID) {
    const route = '/cms/edit/' + userID;
    this._router.navigate([route]);
  }

  remove(userID) {
    if (confirm('Do you want to delete?')) {
      this.isLoading = true;
      this._cmsService.delete(userID, 'pages').subscribe((res: any) => {
        console.log('respo', res);
        if (res.success) {
          this.response = res;
          const start = (this.activePage * this.rowsOnPage - this.rowsOnPage + 1);
          this.itemsTotal = this.itemsTotal - 1;

          if (!(this.itemsTotal >= start)) {
            this.activePage = this.activePage - 1;
          }
          this.toastr.success(res.data.message);
          this.getUsers();
        } else {
          this.isLoading = false;
          this.toastr.error(res.error.message);
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }

  changeStatus(user) {
    let status = '';
    let message = tsMessages.DO_YOU_WANT_TO_ACTIVATE_USER;
    if (user.status === 'active') {
      status = 'deactive';
      message = tsMessages.DO_YOU_WANT_TO_DEACTIVATE_USER;
    } else {
      status = 'active';
    }

    if (confirm(message)) {
      this.isLoading = true;
      this._commanService.changeStatus(user.id, status).subscribe((res: any) => {
        if (res.success) {
          this.response = res;
          const start = (this.activePage * this.rowsOnPage - this.rowsOnPage + 1);
          this.itemsTotal = this.itemsTotal - 1;

          if (!(this.itemsTotal >= start)) {
            this.activePage = this.activePage - 1;
          }
          this._commanService.showAlert(res.data.message, 'alert-success');
          /* reload page. */
          this.getUsers();
        } else {
          this.isLoading = false;
          this._commanService.showAlert(res.error.message, 'alert-danger');
          this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }

  /*Get all Users */
  getUsers(): void {
    this._cmsService.loader('show');
    this._cmsService.getAllUsers(this.rowsOnPage, this.activePage, this.sortTrem, this.searchTerm, this.roles).subscribe((res: any) => {
      this._cmsService.loader('hide');
      this.isLoading = false;
      this.isPageLoading = false;
      if (res.success) {
        this.data = res.data.data;
        this.itemsTotal = res.data.total;
        if (res.data.users && res.data.users.length == 1) { this.activePage = 1; }
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.isLoading = false;
      this.isPageLoading = false;
    });
  }

  public onPageChange(event) {
    this.isLoading = true;
    this.rowsOnPage = event.rowsOnPage;
    this.activePage = event.activePage;
    this.getUsers();
  }

  public onRowsChange(event): void {
    this.isLoading = true;
    this.rowsOnPage = this.itemsOnPage;
    this.activePage = 1;
    this.getUsers();
  }

  public onSortOrder(event) {
    this.sortTrem = this.sortBy + ' ' + this.sortOrder;
    this.isLoading = true;
    this.getUsers();
  }

  public search(event, element = 'input') {
    if (element == 'input') {
      if (event.keyCode == 13 || this.searchTerm == '') {
        this.searchTerm = this.searchTerm.trim();
        this.isLoading = true;
        this.getUsers();
        this.activePage = 1;
        this.getUsers();
      }
    } else {
      this.searchTerm = this.searchTerm.trim();
      this.isLoading = true;
      this.getUsers();
      this.activePage = 1;
      this.getUsers();
    }
  }

}
