import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CmsService } from '../services/cms.service';
import { CookieService } from 'ngx-cookie';
import { CommanService } from '../../shared/services/comman.service';

@Component({
  selector: 'app-view-cms',
  templateUrl: './view-cms.component.html',
  styleUrls: ['./view-cms.component.scss']
})
export class ViewCmsComponent {

  public ID = '';
  public category = { type: '' };
  // public isLoading:boolean   = true;
  public addEditDelete: boolean = false;
  description: any;

  constructor(
    private _router: Router,
    private _activatedRouter: ActivatedRoute,
    private _categoryService: CmsService,
    private _cookieService: CookieService,
    private _commanService: CommanService) {

    let actions = this._commanService.getActions();
    if (actions["type"] == 'SA' || actions['category']['addEditDelete']) this.addEditDelete = true;

    this.ID = _activatedRouter.snapshot.params['id'];

    if (this.ID) {
      this._categoryService.loader('show');
      this._categoryService.get(this.ID).subscribe((res: any) => {
        this._categoryService.loader('hide');
        if (res.success) {
          this.category = res.data;
          this.description = res.data.description
          console.log(this.category, 'this.category>>>>>>')
        } else {
          this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this._categoryService.loader('hide');
        this._commanService.checkAccessToken(err);
      });
    }

  }

  edit(ID) {
    let route = '/cms/edit/' + ID;
    this._router.navigate([route]);
  }


}
