import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CommanService } from '../../shared/services/comman.service';
import { tsConstants } from '../../tsconstant';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {
  private _host = tsConstants.HOST;

  constructor(
    private _http: HttpClient,
    private _commanService: CommanService,
    private spinner: NgxSpinnerService,) {
  }

  /*Use to fetch all Inputs*/
  getAllPlan(userType) {
    const date = new Date().getTime().toString();
    const url = this._host + `/user/get-all-plans?userType=${userType}&count=10&page=1`;

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }
  getAllAddonPlans() {
    const date = new Date().getTime().toString();
    const url = this._host + `/addonplans/get-all`;

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  create(type: string){
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/user/create-plan', {planType: type}, { headers });    
  }

  // /*Use to update plan*/
  update(plan) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/user/updatePlan', plan, { headers });
  }


  loader(key) {
    if (key === 'show') { this.spinner.show(); }
    if (key === 'hide') { this.spinner.hide(); }
  }

  createAddOn(addOnPlan) {
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/addonplans/create', addOnPlan, { headers });
  }
  updateAddOn(addOnPlan) {
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/addonplans/update', addOnPlan, { headers });
  }
}
