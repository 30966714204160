import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { CommanService } from '../../shared/services/comman.service';
import { CategoryService } from '../services/category.service';
import { tsMessages } from '../../tsmessage';
@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  public data: Array<any> = [];
  public tagData: Array<any> = [];
  public activePage = 1;
  private activePageTag = 1;
  public itemsTotal = 0;
  public tagTotal = 0;
  public rowsOnPage = 10;
  public itemsOnPage;
  showAddForm = false;
  showAddTagForm = false;
  editId = '';
  hideEdit = '';
  categoryTitle = '';
  tagTitle = '';
  editCategoryTitle = '';

  editTagTitle = '';
  hideTagEdit = '';
  editTagId = '';
  constructor(
    private _router: Router,
    private categoryService: CategoryService,
    private _commanService: CommanService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getAllCategory();
    this.activePage = 1;
    this.getAllTag();
    this.itemsOnPage = this.rowsOnPage;
  }
  getAllCategory() {
    this._commanService.loader('show');
    this.categoryService.getAllCategory(this.rowsOnPage, this.activePage).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.data = res.data.data;
        this.itemsTotal = res.data.data.length || 0;
        console.log('this.data', this.data);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  getAllTag() {
    this._commanService.loader('show');
    this.categoryService.getAllTag(this.rowsOnPage, this.activePageTag).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.tagData = res.data.data;
        this.tagTotal = res.data.total || 0;
        console.log('this.data', this.tagData);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  changeStatus(user) {
    let status;
    let message = tsMessages.DO_YOU_WANT_TO_ACTIVATE_USER;
    if (user.status) {
      status = false;
      message = tsMessages.DO_YOU_WANT_TO_DEACTIVATE_USER;
    } else {
      status = true;
    }

    if (confirm(message)) {
      this._commanService.loader('show');
      this.categoryService.changeStatus({ id: user._id, status }).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this._commanService.showAlert(res.data.message, 'alert-success');
          /* reload page. */
          this.getAllCategory();
        } else {
          this._commanService.showAlert(res.error.message, 'alert-danger');
          // this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }
  }
  changeTagStatus(user) {
    let status;
    let message = tsMessages.DO_YOU_WANT_TO_DELETE_TAG;
    if (user.status) {
      status = false;
      message = tsMessages.DO_YOU_WANT_TO_DEACTIVATE_USER;
    } else {
      status = true;
    }

    if (confirm(message)) {
      this._commanService.loader('show');
      this.categoryService.changeStatusTag({ id: user._id, status }).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this._commanService.showAlert(res.data.message, 'alert-success');
          /* reload page. */
          this.getAllCategory();
        } else {
          this._commanService.showAlert(res.error.message, 'alert-danger');
          // this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }
  }
  addCategory(evt) {
    this._commanService.loader('show');
    this.categoryService.add({ title: this.categoryTitle }).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.showAddForm = !this.showAddForm;
        this.categoryTitle = '';
        this.getAllTag();
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  addTag(evt) {
    this._commanService.loader('show');
    this.categoryService.addTag({ title: this.tagTitle }).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.showAddForm = !this.showAddForm;
        this.tagTitle = '';
        this.getAllTag();
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  editField(id, title) {
    this.editId = id;
    this.hideEdit = id;
    this.editCategoryTitle = title;

  }
  editTagField(id, title) {
    this.editTagId = id;
    this.hideTagEdit = id;
    this.editTagTitle = title;

  }
  saveEditFiled(id) {
    this._commanService.loader('show');
    this.categoryService.update(id, { title: this.editCategoryTitle }).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.hideEdit = '';
        this.editId = '';
        this.getAllCategory();
        this.toastr.success(res.message);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  saveEditFiledTag(id) {
    this._commanService.loader('show');
    this.categoryService.updateTag(id, { title: this.editTagTitle }).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.hideTagEdit = '';
        this.editTagId = '';
        this.getAllTag();
        this.toastr.success(res.message);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  remove(id) {
    this._commanService.loader('show');
    this.categoryService.delete(id).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.getAllCategory();
        this.toastr.success(res.message);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }
  removeTag(id) {
    this._commanService.loader('show');
    this.categoryService.deleteTag(id).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.getAllTag();
        this.toastr.success(res.message);
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }

  onChangePageCategory(e: any) {
    this.activePage = e.page;
    this.getAllCategory();
  }

  onChangePageTag(e: any) {
    this.activePageTag = e.page;
    this.getAllTag();
  }
  // public onSortOrder(event) {
  //   this.sortTrem = this.sortBy + ' ' + this.sortOrder;
  //   this.isLoading = true;
  //   this.getUsers();
  // }
}
