import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { ListBlogsComponent } from './list-component/list-blogs.component';
import { AddUpdateBlogsComponent } from './addupdate-component/addupdate-blogs.component';
import { ViewBlogsComponent } from './view-component/view-blogs.component';
import { ChildRouteGuard } from '../auth/services/child-route-guard';
import { HttpClientModule }    from '@angular/common/http';
import { BlogsService } from './services/blogs.service';
import { ListBlogsByUserComponent } from './list-blogs-by-user/list-blogs-by-user.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Blogs'
    },
    children: [
      {
        path: '',
        component: ListBlogsComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListBlogsComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      // {
      //   path: 'add',
      //   component: AddUpdateBlogsComponent,
      //   // canActivate: [ChildRouteGuard],
      //   data: {
      //     title: 'Add Blogs'
      //   }
      // },
      // {
      //   path: 'list/:id',
      //   component: ViewBlogsComponent,
      //   // canActivate: [ChildRouteGuard],
      //   data: {
      //     title: 'View Blogs'
      //   }
      // },
      // {
      //   path: 'edit/:id',
      //   component: AddUpdateBlogsComponent,
      //   // canActivate: [ChildRouteGuard],
      //   data: {
      //     title: 'Edit Blogs'
      //   }
      // },

      { path: 'list-by-users/:page', component: ListBlogsByUserComponent },
      { path: 'list-by-users', redirectTo: '/blogs/list-by-users/1' },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    HttpClientModule
  ],
  providers: [
    BlogsService
  ],
  exports: [
    RouterModule,
    FormsModule
  ]
})
export class BlogsRoutingModule {}
