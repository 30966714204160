import { Component, OnInit } from '@angular/core';
import { CacheService } from './shared/services/cache.service';
import { CommanService } from './shared/services/comman.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'goodDeeds';

  constructor(
    private _service: CommanService,
    private _cacheService: CacheService,
  ) {}

  ngOnInit() {
    this._service.get('/questionare/get-questions?type=SP').subscribe((res: any) => {
      if(res.statusCode == 200) {
        this._cacheService.saveQuestionnaire(res.data);
      }
    });

    this._service.get('/questionare/get-service').subscribe((res: any) => {
      if(res.statusCode == 200) {
        for(let service of res.data) {
          if(service.category_type.toLowerCase() == 'goal') {
            this._cacheService.saveCategories(service.category);
            break;
          }
        }
      }
    });
  }
}
