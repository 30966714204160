'use strict';

export const tsMessages = {

  RECORD_UPDATED_SUCCESSFULLY: 'Record Updated successfully.',
  RECORD_ADDED_SUCCESSFULLY: 'Record Added successfully.',
  NO_SUCH_USER_EXIST: 'No such user exist.',
  YOUR_SESSION_HAS_EXPIRED_PLEASE_LOGIN_AGAIN: 'Your session has expired. Please login again.',
  YOU_ARE_NOT_AUTHORIZED_PLEASE_CONTACT_MARKET_ADMIN: 'You are not authorized please contact market admin.',
  EMAIL_OR_PASSWORD_IS_NOT_CORRECT: 'Email or Password is not correct.',
  EDIT_YOUR_CONTENT_HERE: 'Edit Your Content Here!',
  DELETED_SUCCESSFULLY: 'Deleted successfully.',
  PINCODE_IS_REQUIRED: 'Pin Code is required.',
  MINIMUM_ONE_PINCODE_IS_REQUIRED: 'Minimum One Pin Code is required.',
  MAXIMUM_6_DIGIT_ALLOW: 'Maximum 6 digit allow.',
  MINIMUM_6_DIGIT_ALLOW: 'Minimum 6 digit allow.',
  ALREADY_EXISTS: 'Already exist.',
  REASON_IS_REQUIRED: 'Reason is required.',
  NAME_IS_REQUIRED: 'Name is required.',
  INVALID_INPUT: 'Invalid input.',
  VARIETY_IS_REQUIRED: 'Variety is required.',
  EMAIL_IS_NOT_VALID: 'Email is not valid.',
  MINIMUM_10_DIGIT_ALLOW: 'Minimum 10 digit allow.',
  MAXIMUM_10_DIGIT_ALLOW: 'Maximum 10 digit allow.',
  MOBILE_IS_NOT_VALID: 'Mobile is not vaild',
  PLEASE_SELECT_USER: 'Please select user.',
  USER_ALREADY_ADDED_IN_THIS_GROUP: 'User already added in this group.',
  DO_YOU_WANT_TO_ACTIVATE_USER: 'Do you want to Activate User?',
  DO_YOU_WANT_TO_DELETE_TAG: 'Do you want to delete the tag?',
  DO_YOU_WANT_TO_DEACTIVATE_USER: 'Do you want to Deactivate User?',
  SERVER_ERROR: 'An error occcurd, please try again later.',
  PLEASE_SELECT_ATLEAST_ONE_DASHBOARD: 'Please select atleast one dashboard.'

};
