import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CommanService } from '../../shared/services/comman.service';

@Component({
  selector: 'app-myprofile',
  templateUrl: 'myprofile.component.html'
})
export class MyprofileComponent implements OnInit {


  public errMessage = '';
  public successMessage = '';
  public isPageLoading = false;
  public isLoading = false;
  public currentUser: any = {};
  public user = { id: '', currentPassword: '', newPassword: '', confirmPassword: '' };


  constructor(
    private _router: Router,
    private _profileService: ProfileService,
    private _commanService: CommanService,
    private _cookieService: CookieService) { }

  ngOnInit() {
    this.isPageLoading = true;
    this.getCurrentUser();
  }


  getCurrentUser() {
    this.errMessage = '';
    this.isPageLoading = false;
    this._profileService.getcurrentuser().subscribe(res => {

      this.currentUser = res;
      this.user.id = this.currentUser.id;
    });
  }

}
