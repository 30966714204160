import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ngx-pagination-bootstrap';
import { ListUserComponent } from './list-component/list-user.component';
import { ViewUserComponent } from './view-component/view-user.component';
import { ChildRouteGuard } from '../auth/services/child-route-guard';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user.service';
import { AddSectionComponent } from './add-section/add-section.component';
import { EditSectionComponent } from './edit-section/edit-section.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Users'
    },
    children: [
      {
        path: '',
        component: ListUserComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListUserComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'List'
        }
      },
      {
        path: 'list/:id',
        component: ViewUserComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'View'
        }
      },
      {
        path: 'add',
        component: AddSectionComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'Add'
        }
      },
      {
        path: 'edit/:id',
        component: EditSectionComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'Edit'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    Ng2TableModule,
    PaginationModule,
    HttpClientModule
  ],
  providers: [
    UserService
  ],
  exports: [
    RouterModule,
    FormsModule,
    Ng2TableModule,
    PaginationModule
  ]
})
export class UsersRoutingModule { }
