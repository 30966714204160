import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommanService } from './shared/services/comman.service';

import { NAV_DROPDOWN_DIRECTIVES } from './shared/nav-dropdown.directive';

import { CookieModule } from 'ngx-cookie';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ActiveRouteGuard } from './auth/services/activate-route-guard';
import { DeactiveRouteGuard } from './auth/services/deactivate-route-guard';
import { ChildRouteGuard } from './auth/services/child-route-guard';
import { FullLayoutService } from './layouts/full-layout.service';

import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { AsideToggleDirective } from './shared/aside.directive';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FlashMessagesModule } from 'ngx-flash-messages';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrModule } from 'ngx-toastr';
import { ThemeService } from 'ng2-charts';
import { ErrorInterceptor } from './shared/error.interceptor';




// import { CustomFormsModule } from 'ng2-validation';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    UiSwitchModule,
    // TabsModule.forRoot(),
    // PaginationModule.forRoot(),
    CookieModule.forRoot(),
    // HttpModule,
    HttpClientModule,
    // CustomFormsModule,
    FlashMessagesModule,
    ModalModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })
  ],
  declarations: [
    AppComponent,
    // FullLayoutComponent,
    NAV_DROPDOWN_DIRECTIVES,
    // BreadcrumbsComponent,
    // SubHeaderComponent,
    // SidebarComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,


  ],
  providers: [CommanService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    ActiveRouteGuard,
    DeactiveRouteGuard,
    ChildRouteGuard,
    FullLayoutService,
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
