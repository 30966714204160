import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule } from 'ng-angular8-datatable';

import { SubscriptionPlanService } from '../subscription-plan/services/subscription-plan.service';




import { SharedModule } from '../shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { FlashMessagesModule } from 'ngx-flash-messages';

import { SubscriptionPlanRoutingModule } from './subscription-plan-routing.module';

import { ListComponent } from './list/list.component';
import { AddupdateComponent } from './addupdate/addupdate.component';

import { ViewSubscriptionComponent } from './view-subscription/view-subscription.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EdiSubscriptionComponent } from './edi-subscription/edi-subscription.component';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
  declarations: [ListComponent, AddupdateComponent, ViewSubscriptionComponent, EdiSubscriptionComponent],
  imports: [
    CommonModule,
    SubscriptionPlanRoutingModule,
    UiSwitchModule,
    SharedModule,
    DataTableModule,
    CustomFormsModule,
    FlashMessagesModule,
    NgxSpinnerModule
  ],
  providers: [
    SubscriptionPlanService
  ],
})
export class SubscriptionPlanModule { }
