import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CommanService } from '../../shared/services/comman.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie';
import { tsConstants } from '../../tsconstant';

@Injectable()
export class CategoryService {

  private _host = tsConstants.HOST;

  constructor(
    private _http: HttpClient,
    private _commanService: CommanService,
    private _cookieService: CookieService) {
  }

  getAllCategory(rowsOnPage, activePage) {
    const url = `${this._host}/category/get-all?count=${rowsOnPage}&page=${activePage}&frontend=0`;
    console.log('URL', url);
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  /*Use to add new blogs*/
  add(body) {
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/category/create', body, { headers });
  }

  /*Use to get blogs with blog id*/
  get(blogID) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/blog/get-by-id/' + blogID, { headers });
  }

  /*Use to update blogs*/
  update(id, category) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.put(this._host + '/category/update/' + id, category, { headers });
  }


  /* Delete blogs from list */
  delete(ID) {

    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + '/category/delete/' + ID;
    return this._http.delete(url, { headers });
  }

  getUserID() {
    const userData: any = this._cookieService.getObject('userData');
    if (userData) {
      return userData.id;
    }
  }

  changeStatus(status) {
    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + '/category/updateStatus/';
    return this._http.put(url, status, { headers });
  }
  /*Use to add new blogs*/
  getAllTag(rowsOnPage, activePage) {
    const url = `${this._host}/tag/get-all?count=${rowsOnPage}&page=${activePage}&frontend=0`;
    console.log('URL', url);
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(url, { headers });
  }

  addTag(body) {
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/tag/create', body, { headers });
  }

  /*Use to update blogs*/
  updateTag(id, category) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.put(this._host + '/tag/update/' + id, category, { headers });
  }


  /* Delete blogs from list */
  deleteTag(ID) {

    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + '/tag/delete/' + ID;
    return this._http.delete(url, { headers });
  }

  getUserIDTag() {
    const userData: any = this._cookieService.getObject('userData');
    if (userData) {
      return userData.id;
    }
  }

  changeStatusTag(status) {
    const headers = this._commanService.getAuthorizationHeader();
    const url = this._host + '/tag/updateStatus/';
    return this._http.put(url, status, { headers });
  }

}
