'use strict';

import { environment } from 'src/environments/environment';

export const tsConstants = {


  // HOST: "http://18.216.244.101:1337",
  // HOST: "http://3.12.81.245:1337",
  // HOST: "https://prompthealth.ca:3000/api/v1",
  // HOST: "https://localhost:3000/api/v1",
  // HOST: "http://3.12.81.245:3000/api/v1",

  HOST: environment.config.API_URL,
  CLIENT_ID: '5x7EuN09HAeBn2pYJnvvq7szgJaULh14',
  GRANT_TYPE: 'password',
  DATE_FORMAT: 'dd/MM/yyyy',
  DATE_FORMAT_FOR_DATEPICKER: 'dd/mm/yyyy',
  ALERT_TIME: 3000
};
