import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ngx-pagination-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ChildRouteGuard } from '../auth/services/child-route-guard';

import { ListComponent } from './list/list.component';
import { AddupdateComponent } from './addupdate/addupdate.component';
import { ViewSubscriptionComponent } from './view-subscription/view-subscription.component';
import { EdiSubscriptionComponent } from './edi-subscription/edi-subscription.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'SubscriptionPlan'
    },
    children: [
      {
        path: '',
        component: ListComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'SubscriptionPlanList'
        }
      },
      {
        path: 'list',
        component: ListComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'SubscriptionPlanList'
        }
      },
      {
        path: 'add',
        component: AddupdateComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'Add SubscriptionPlan'
        }
      },
      {
        path: 'list/:id',
        component: ViewSubscriptionComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'View SubscriptionPlan'
        }
      },
      {
        path: 'edit/:id',
        component: EdiSubscriptionComponent,
        // canActivate: [ChildRouteGuard],
        data: {
          title: 'Edit Plan'
        }
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    FormsModule,
    Ng2TableModule,
    PaginationModule,
    HttpClientModule],

  exports: [RouterModule,
    FormsModule,
    Ng2TableModule,
    PaginationModule]
})
export class SubscriptionPlanRoutingModule { }
