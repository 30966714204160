import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { CommanService } from '../../shared/services/comman.service';
import { FullLayoutService } from '../../layouts/full-layout.service';

@Component({
  selector: 'field-officer-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public totalSP = 0;
  public totalCentre;
  public totalBlog;
  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Centre' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Service Provider' }
  ];

  public demographicData: DemographicData;

  public chartDataDemographic: {[k:string]: ChartDataSets[]} = {
    SP: [{ data: [10, 20, 30, 40, 50], label: 'Service Provider'}],
    C:  [{ data: [10, 20, 30, 40, 50], label: 'Centre'}],
    P:  [{ data: [10, 20, 30, 40, 50], label: 'Products/Services'}],
  }

  public chartLabelsDemographic: {[k: string]: Label[] } = {
    SP:['Vancouver', 'Tronto', 'Suerry', 'Abbotsford', 'Burnaby'],
    C: ['Vancouver', 'Tronto', 'Suerry', 'Abbotsford', 'Burnaby'],
    P: ['Vancouver', 'Tronto', 'Suerry', 'Abbotsford', 'Burnaby'],
  }


  public demographicControllers: {[k: string]: DemographicController } = {
    SP:{desc: true, sortBy: 'count', type: 'area'},
    C:{ desc: true, sortBy: 'count', type: 'area'},
    P: {desc: true, sortBy: 'count', type: 'area'},
  }

  getRoleLabel(role: string) {
    let s = '';
    switch(role) {
      case 'SP': s = 'Service Provider'; break;
      case 'C': s = 'Centre'; break; break;
      case 'P': s = 'Products/Services'; break;
    }
    return s;
  }

  constructor(
    private _fullLayoutService: FullLayoutService,
    private _commanService: CommanService,
    private toastr: ToastrService,) {

  }

  ngOnInit() {
    this.getSPUsers();
    this.getCentreUsers();
    this.getBlogs();
    this.getGraphData();
    this.getDemographicData();    
  }
  /*Get SP Users */
  getSPUsers(): void {
    this._commanService.loader('show');
    // if(this.roles === 'all') this.roles = ''
    const path = '/user/get-all-user?role=SP';
    this._commanService.get(path).subscribe((res: any) => {
        this._commanService.loader('hide');
        if (res.statusCode === 200) {
            this.totalSP = res.data.total;
            console.log(res.data);
        } else {
            this._commanService.checkAccessToken(res.error);
        }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }

  /*Get Centre Users */
  getCentreUsers(): void {
    this._commanService.loader('show');
    // if(this.roles === 'all') this.roles = ''
    const path = '/user/get-all-user?role=C';
    this._commanService.get(path).subscribe((res: any) => {
        this._commanService.loader('hide');
        if (res.statusCode === 200) {
            this.totalCentre = res.data.total;
        } else {
            this._commanService.checkAccessToken(res.error);
        }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }

  /*Get Centre Users */
  getBlogs(): void {
    this._commanService.loader('show');
        let path = `/blog/get-all?count=&page=&frontend=0&categoryId=`;

    this._commanService.get(path).subscribe((res: any) => {
        this._commanService.loader('hide');
        if (res.statusCode === 200) {
            this.totalBlog = res.data;
            console.log('Blog', res.data);
        } else {
            this._commanService.loader('hide');
            this._commanService.checkAccessToken(res.error);
        }
    }, err => {
        this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }

  getGraphData() {
    const currentYear = new Date().getFullYear();
    this._commanService.loader('show');
    const path = `/user/dashboard?year=${currentYear}`;
    this._commanService.get(path).subscribe((res: any) => {
        this._commanService.loader('hide');
        if (res.statusCode === 200) {
            this.barChartData = res.data;
        } else {
            this._commanService.checkAccessToken(res.error);
        }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }

  getDemographicData() {
    const path = '/user/get-all-user-demographic';
    this._commanService.get(path).subscribe((res: {
      statusCode: number, 
      data: DemographicData,
    }) => {
      if(res.statusCode === 200) {
        this.demographicData = res.data;
        console.log(res.data)
        for(let role in this.demographicData.area) {
          this.sortDemographicDataByCount(role);
          this.setChartDemographic(role);
        }
      }
    });
  }

  setChartDemographic(role: string) {
    const labels: Label[] = [];
    const data: ChartDataSets = {data: [], label: role};

    const type = this.demographicControllers[role].type;
    for (let d of this.demographicData[type][role]) {
      labels.push(d.label);
      data.data.push(d.count);
    }
    this.chartDataDemographic[role] = [data];
    this.chartLabelsDemographic[role] = labels;
  }

  sortDemographicDataByCount(role: string, desc: boolean = true) {
    const type = this.demographicControllers[role].type;
    const data: {label: string, count: number}[] = this.demographicData[type][role];
    data.sort((a,b)=>{
      let[countA, countB] = [a.count, b.count];
      if(a.label.toLowerCase() == 'no data') { countA = desc ? 0 : 100000000; }
      if(b.label.toLowerCase() == 'no data') { countB = desc ? 0 : 100000000; }

      if(desc) { return countB - countA; }
      else { return countA - countB; }
    });
  }

  sortDemographicDataByName(role: string, desc: boolean = false) {
    const type = this.demographicControllers[role].type;
    const data: {label: string, count: number}[] = this.demographicData[type][role];
    
    data.sort((a, b) => {
      if(a.label.toLowerCase() == 'no data' || b.label.toLowerCase() == 'no data'){
        return 1;
      }else if(b.label > a.label) {
        return desc ? 1 :  -1
      }else{
        return !desc ? 1 : -1
      }      
    });
  }

  onClickChangeDemographicType(role: string, type: DemographicController['type']){
    this.demographicControllers[role].type = type;
    this.demographicControllers[role].sortBy = 'count';
    this.demographicControllers[role].desc = true;
    this.sortDemographicDataByCount(role, this.demographicControllers[role].desc);
    this.setChartDemographic(role);
  }

  onClickSortDemographicByName(role: string) {
    this.demographicControllers[role].desc = (this.demographicControllers[role].sortBy == 'name') ? !this.demographicControllers[role].desc : false;
    this.demographicControllers[role].sortBy = 'name';

    this.sortDemographicDataByName(role, this.demographicControllers[role].desc);
    this.setChartDemographic(role);
  }

  onClickSortDemographicByCount(role: string) {
    this.demographicControllers[role].desc = (this.demographicControllers[role].sortBy == 'count') ? !this.demographicControllers[role].desc : true;
    this.demographicControllers[role].sortBy = 'count';

    this.sortDemographicDataByCount(role, this.demographicControllers[role].desc);
    this.setChartDemographic(role);
  }

}


type DemographicController = {desc: boolean, sortBy: 'name' | 'count', type: 'area' | 'typeOfProvider'};


type DemographicData = {
  area: {
    SP: {label: string, count: number}[];
    C: {label: string, count: number}[];
    P: {label: string, count: number}[];  
  };
  typeOfProvider: {
    SP: {label: string, count: number}[];
    C: {label: string, count: number}[];
    P: {label: string, count: number}[];  
  }
}
