import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule } from 'ng-angular8-datatable';
import { ListUserComponent } from './list-component/list-user.component';
import { ViewUserComponent } from './view-component/view-user.component';
import { UsersRoutingModule } from './users-routing.module';
import { CustomFormsModule } from 'ng2-validation';
import { SharedModule } from '../shared/shared.module';
import { ImageUploadModule } from 'ng2-imageupload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AddSectionComponent } from './add-section/add-section.component';
import { EditSectionComponent } from './edit-section/edit-section.component';
import { DialogService } from 'ng2-bootstrap-modal';



@NgModule({
  imports: [
    UsersRoutingModule,
    CommonModule,
    DataTableModule,
    CustomFormsModule,
    SharedModule,
    ImageUploadModule,
    ModalModule,
    NgxSpinnerModule
  ],
  providers: [
    DialogService
  ],
  declarations: [
    ListUserComponent,
    ViewUserComponent,
    AddSectionComponent,
    EditSectionComponent,
  ]
})
export class UsersModule { }
