import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { DataTableModule } from 'ng-angular8-datatable';
import { DataTableModule } from 'ng-seven-datatable';
import { CategoryService } from '../category-management/services/category.service';
import { ListingComponent } from '../category-management/listing/listing.component';
import { CategoryRoutingModule } from './category-routing.module';
import { PaginationModule,PaginationConfig } from 'ngx-bootstrap/pagination';



@NgModule({
  declarations: [
    ListingComponent
  ],
  imports: [
    CategoryRoutingModule,
    CommonModule,
    DataTableModule,
    ModalModule,
    PaginationModule,
    NgxSpinnerModule,
  ],
  providers: [
    CategoryService,
    PaginationConfig,
  ],
})
export class CategoryManagementModule { }
