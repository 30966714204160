import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CommanService } from '../../shared/services/comman.service';
import { tsConstants } from '../../tsconstant';

@Injectable()

export class ProfileService {

  private _host = tsConstants.HOST;
  private _accessToken = '';

  constructor(
    private _http: HttpClient,
    private _commanService: CommanService) {
  }


  getcurrentuser() {
    const headers = this._commanService.getAuthorizationHeader();
    return this._http.get(this._host + '/user/current', { headers });
  }

  changePassword(userData) {

    const headers = this._commanService.getAuthorizationHeader();
    return this._http.post(this._host + '/user/change-password', userData, { headers });
  }
}
