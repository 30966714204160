import { Component, ChangeDetectorRef, ViewChild, OnInit } from '@angular/core';
import { BlogsService } from '../services/blogs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommanService } from '../../shared/services/comman.service';
import { tsMessages } from '../../tsmessage';
import { environment } from '../../../environments/environment';
import { ImageResult, ResizeOptions } from 'ng2-imageupload';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';

@Component({
  templateUrl: 'addupdate-blogs.component.html'
})

export class AddUpdateBlogsComponent implements OnInit {

  get fVideos() {
    return this.mediaForm.controls.videoLinks as FormArray;
  }
  get fPodcasts() {
    return this.mediaForm.controls.podcastLinks as FormArray;
  }
  // private languageList: Array<object> = [];

  constructor(
    private _router: Router,
    private _fb: FormBuilder,
    private _activateRouter: ActivatedRoute,
    private _blogService: BlogsService,
    private _commanService: CommanService,
    private toastr: ToastrService
  ) {

    this.AWS_S3 = environment.config.AWS_S3;
    this.mediaForm = this._fb.group({
      videoLinks: new FormArray([]),
      podcastLinks: new FormArray([]),
    });

    this.blogID = _activateRouter.snapshot.params.id;
    if (this.blogID) {
      this._commanService.loader('show');
      this._blogService.get(this.blogID).subscribe((res: any) => {
        this._commanService.loader('hide');
        if (res.statusCode === 200) {
          this.blog = res.data;
          this.blog.categoryId = this.blog.categoryId ? this.blog.categoryId._id : null;
          this.blog.eventStartTime = (this.blog.eventStartTime) ? new Date(this.blog.eventStartTime) : null;
          this.blog.eventEndTime = (this.blog.eventEndTime) ? new Date(this.blog.eventEndTime) : null

          if (this.blog.videoLinks && this.blog.videoLinks.length > 0) {
            for (const data of this.blog.videoLinks) {
              this.fVideos.push(this.initItemRows(data));
            }
          } else {
            this.addNewRowOf(this.fVideos);
          }

          if (this.blog.podcastLinks && this.blog.podcastLinks.length > 0) {
            for (const data of this.blog.podcastLinks) {
              this.fPodcasts.push(this.initItemRows(data));
            }
          } else {
            this.addNewRowOf(this.fPodcasts);
          }
        } else {
          // this.toastr.error(res.error, 'Error');
          // this._commanService.checkAccessToken(res.error);
        }
      }, err => {
        this.toastr.error('Error: ' + err.error.message);
        if(err.statusText == 'Unauthorized') {
          this._router.navigate(['/login']);
        }
      });
    } else {
      this._commanService.loader('hide');

      this.addNewRowOf(this.fPodcasts);
      this.addNewRowOf(this.fVideos);
    }

    /*this._commanService.getAllCategories('blogs').subscribe( res => {
         this.category = res.data;
    }, err => {});*/
  }

  @ViewChild('myInput', { static: false })
  myInputVariable: any;
  imagesList;
  reg = /^http(s)?:\/\/(w{3}\.)?((youtube|vimeo|open\.spotify)\.com|youtu.be)\/.+/;

  // public _host = environment.config.HOST;

  public blog: any = {
    title: '',
    categoryId: '',
    authorId: localStorage.getItem('adminId'),
    tags: [],
    author: '',
    description: '',
    image: '',
    podcastLinks: [],
    videoLinks: [],
    headliner: false,
    joinEventLink: null,
    eventStartTime: null,
    eventEndTime: null,
  };
  mediaForm: FormGroup;
  public todayDate = new Date();
  public descriptionError = false;
  public blogID: any;
  public rowsOnPage = '';
  public activePage = '';
  public sortTrem = '';
  public data = [];
  public itemsTotal = 0;
  public searchTerm = '';
  public categoryList;
  public tagList;
  public AWS_S3 = '';
  public dropdownSettings: IDropdownSettings;
  public option: Object = {
    placeholderText: tsMessages.EDIT_YOUR_CONTENT_HERE,
    charCounterCount: false
  };
  fTitleOf(fg: FormGroup) { return fg.controls.title; }
  fUrlOf(fg: FormGroup) { return fg.controls.url; }
  initItemRows(data: { title: string, url: string } = null) {
    return this._fb.group({
      // list all your form controls here, which belongs to your form array
      title: [data ? data.title : '', [Validators.required]],
      url: [data ? data.url : '', [Validators.required, Validators.pattern(this.reg)]],
    });
  }
  addNewRowOf(fa: FormArray) {
    fa.push(this.initItemRows());
  }

  deleteRowOf(fa: FormArray, index: number) {
    fa.removeAt(index);
  }

  consoleLog(e) {
    console.log(e);
  }

  ngOnInit(): void {
    this.getAllCategory();
    this.getAllTags();
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


  }

  getAllCategory() {
    this._commanService.loader('show');
    const path = `/category/get-all`;
    this._commanService.get(path).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.categoryList = res.data;
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
    });
  }

  getAllTags() {
    this._commanService.loader('show');
    const path = `/tag/get-all`;
    this._commanService.get(path).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.tagList = res.data;
      } else {
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
    });
  }
  onItemSelect(event) {
    console.log(event);
    console.log('blog tag', this.blog.tags);
    // this.blog;
  }

  /*If blogID exist then will update existing blog otherwise will add new blog*/
  save() {
    const mediaLinks: any = {};

    // validate links
    //// if either of title and url are invalid --> ng (stop update)
    //// if both of title and url is empty --> ok (but no data)
    //// if both of title and url are valid --> ok
    for (const faName in this.mediaForm.controls) {
      if (this.mediaForm.controls[faName]) {

        const fa = this.mediaForm.controls[faName] as FormArray;
        const linkArray = [];
        for (let _fg of fa.controls) {
          const fg = _fg as FormGroup;
          const title = fg.controls.title;
          const url = fg.controls.url;

          if (fg.invalid) {
            if (title.value || url.value) {
              if (title.errors && title.errors.required) {
                this.toastr.error(`ERROR: title of ${faName} is required`);
              }
              if (url.errors) {
                if (url.errors.required) {
                  this.toastr.error(`ERROR: url of ${faName} is required`);
                } else {
                  this.toastr.error(`ERROR: url of ${faName} is invalid`);
                }
              }
              return false;
            }
          } else {
            /** replaceUrl is done in frontend. */
            // if (url.value.match(/spotify/)) {
            //   const urlReplaced = url.value.replace(/\?.*/, '').replace('episode', 'embed/episode');
            //   url.setValue(urlReplaced);
            // }
            linkArray.push(fg.value);
          }
        }
        mediaLinks[faName] = linkArray;
      }
    }

    this.blog.title = this.blog.title.trim();
    if (!this.blog.title) { return; }
    if (!this.blog.description) {
      this.descriptionError = true;
      return;
    }
    if (this.blogID) {
      console.log('blog', this.blog);
      const payload = {
        ...mediaLinks,
        title: this.blog.title,
        author: this.blog.author,
        description: this.blog.description,
        tags: this.blog.tags,
        categoryId: this.blog.categoryId,
        image: this.blog.image,
        headliner: this.blog.headliner,
        joinEventLink: this.blog.joinEventLink,
        eventStartTime: this.blog.eventStartTime,
        eventEndTime: this.blog.eventEndTime,
        authorId: localStorage.getItem('adminId'),

      };
      console.log('payload', payload);
      this._commanService.loader('show');
      this._blogService.edit(payload, this.blogID).subscribe((res: any) => {
        this._commanService.loader('hide');
        this.toastr.success(res.message);
        // this._router.navigate(['/blogs/list']);
      }, err => {
        console.log(err);
        this._commanService.loader('hide');
      });
    } else {
      this._commanService.loader('show');
      console.log('blog',this.blog);
      const payload = {
        ...mediaLinks,
        ...this.blog,
        authorId: localStorage.getItem('adminId')
      };
      console.log('payload', payload);
      this._blogService.add(payload).subscribe((res: any) => {
        this._commanService.loader('hide');
        if (res.statusCode === 200) {
          this.toastr.success(res.message);
          // this._router.navigate(['/blogs/list']);
        } else {
          window.scrollTo(0, 0);
          this.toastr.error(res.message, 'Error');
        }
      }, err => {
        console.log(err.error.message);
        this._commanService.loader('hide');
        this.toastr.error(err.error.message);
      });
    }
  }

  onFileSelect(event) {
    console.log(event);
    if (event.target.files[0].type === 'image/png'
      || event.target.files[0].type === 'image/jpg'
      || event.target.files[0].type === 'image/jpeg') {
      this._commanService.loader('show');
      const formData: FormData = new FormData();
      const input = new FormData();
      input.append('imgLocation', 'blogs');

      input.append('images', event.target.files[0]);
      this._commanService.imgUpload(input, '/common/imgUpload').subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.imagesList = res.data;
          this.blog.image = this.imagesList;
          console.log('res,', this.imagesList);
        }
        this._commanService.loader('hide');
      },
        error => {
          console.log(error);
          this._commanService.loader('hide');
        }
      );
    } else {
      this.toastr.error('This type of file is not supportable!');
    }

  }
  onFileUploadRequest(evt) {
    console.log('evt', evt);
  }


  async onEditorChanged(e: EditorChangeContent | EditorChangeSelection) {
    if('html' in e) {
      if(e.html) {
        const regExImageBase64 = /<img src="(data:image\/.+;base64,.+)"(\/)?>/
        const matchImage = e.html.match(regExImageBase64);
        if(matchImage) {
          this.onImageSelected(matchImage[1]);
        } 

        const regExSpotifyEmbedded = /<iframe(.*)src="https:\/\/open\.spotify\.com\/(track|playlist|show|episode)/;
        const matchSpotify = e.html.match(regExSpotifyEmbedded);

        if(matchSpotify) {
          const replaced = `<iframe${matchSpotify[1]}src="https://open.spotify.com/embed/${matchSpotify[2]}`;
          this.blog.description = e.html.replace(regExSpotifyEmbedded, replaced);
          console.log(this.blog.description);
        }
      }
    }
  }

  // this is fired when user select image in quill editor
  // shrink image, upload, replace image src from base64 to file link
  onImageSelected(uri: string): Promise<void>{
    return new Promise( async (resolve, reject) => {
      const blob = this._commanService.b64ToBlob(uri);
      const shrink = await this._commanService.shrinkImageByFixedWidth(blob, 840);
      const path = '/common/imgUpload';

      this._commanService.loader('show');
      const formdata = new FormData();
      formdata.append('imgLocation', 'blogs');
      formdata.append('images', shrink.file, shrink.filename);
      this._commanService.imgUpload(formdata, path).subscribe((res: any) => {
        this._commanService.loader('hide');
        if(res.statusCode === 200) {
          const url = this.AWS_S3 + res.data;
          const replaced = this.blog.description.replace(uri, url);
          this.blog.description = replaced;
          resolve();
        } else {
          console.log(res.message);
          this.toastr.error('Something went wrong. Please try again later');
          reject();
        }
      }, error => {
        this._commanService.loader('hide');
        console.log(error);
        this.toastr.error('Something went wrong. Please try again later');
        reject();
      });
    });
  }

}
