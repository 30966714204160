import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BlogsService } from '../services/blogs.service';
import { CommanService } from '../../shared/services/comman.service';
import { tsMessages } from '../../tsmessage';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blogs',
  templateUrl: './list-blogs.component.html',
  styleUrls: ['./list-blogs.component.scss']
})
export class ListBlogsComponent implements OnInit {

  // private _host = environment.config.HOST;
  // lang: string = 'en';

  linkToProfile(authorId: string) {
    return environment.config.FRONTEND_URL + '/community/profile/' +authorId;
  }

  linkToContent(postId: string) {
    return environment.config.FRONTEND_URL + '/community/content/' + postId;
  }

  summaryDescription(desc: string) {
    if(!desc) {
      desc = '';
    }
    return desc.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s{2,}/, " ").substr(0, 256);
  }

  public data: Array<any> = [];
  public dataPerPage = []
  public counts = {
    today: 0,
    todayBySA: 0,
    thisWeek: 0,
    thisWeekBySA: 0,
    thisMonth: 0,
    thisMonthBySA: 0
  }
  public countsAuthor = {}
  public countsAuthorRanking = [];

  public searchTerm = '';
  public activePage = 1;
  public itemsTotal = 0;
  public rowsOnPage = 50;
  public sortBy = 'createdAt';
  public sortOrder = 'desc';
  public sortTrem = '';
  public categoryList;
  public categoryFilter = 'all';
  public AWS_S3 = '';
  public FRONTEND_URL = environment.config.FRONTEND_URL;
  public itemsOnPage;
  public response: any;
  public addEditDelete = false;
  imagesList = 'assets/img/news/img01.jpg';

  public constructor(
    private _router: Router,
    private _blogService: BlogsService,
    private _commanService: CommanService,
    private toastr: ToastrService) {

    const actions = this._commanService.getActions();
    // if (actions["type"] == 'SA' || actions['blogs']['addEditDelete']) this.addEditDelete = true;
    this.addEditDelete = true;

    this.AWS_S3 = environment.config.AWS_S3;
  }

  ngOnInit(): void {
    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    /*set initial sort condition */
    // this.sortTrem = 'createdAt' + ' ' + this.sortOrder;

    /*Load data*/
    this.activePage = 1;
    this.getBlogs();
    // this.getAllCategory();

    this.itemsOnPage = this.rowsOnPage;

  }
  makeHeadliner(blogID) {
    console.log(blogID);
    this._commanService.loader('show');
    this._blogService.makeHeadliner(blogID).subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.response = res;
        const start = (this.activePage * this.rowsOnPage - this.rowsOnPage + 1);
        this.itemsTotal = this.itemsTotal - 1;

        if (!(this.itemsTotal >= start)) {
          this.activePage = this.activePage - 1;
        }
        this.toastr.success(res.message);
        /* reload page. */
        this.getBlogs();
      } else {
        this._commanService.loader('hide');
        this.toastr.error(res.message, 'Error');
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });

  }
  // getAllCategory() {
  //   this._commanService.loader('show');
  //   const path = `/category/get-all`;
  //   this._commanService.get(path).subscribe((res: any) => {
  //     this._commanService.loader('hide');
  //     if (res.statusCode === 200) {
  //       this.categoryList = res.data.data;
  //     } else {
  //       this._commanService.checkAccessToken(res.error);
  //     }
  //   }, err => {
  //   });
  // }

  // categoryFilterList(evt) {
  //   this._commanService.loader('show');
  //   if (evt.target.value === 'all') {
  //     this.sortTrem = '';
  //     this.getBlogs();
  //   } else {
  //     // let language   =  this.lang;
  //     this.sortTrem = evt.target.value;
  //     this.getBlogs();
  //   }
  // }
  // view(blogID) {
  //   const route = '/blogs/list/' + blogID;
  //   this._router.navigate([route]);
  // }

  // edit(blogID) {
  //   const route = '/blogs/edit/' + blogID._id;
  //   this._router.navigate([route]);
  // }

  // changeStatus(blog, changeTo) {
  //   console.log('blog', blog);

  //   this._commanService.loader('show');
  //   this._blogService.changeStatus(blog._id, changeTo).subscribe((res: any) => {
  //     this._commanService.loader('hide');
  //     if (res.statusCode === 200) {
  //       const start = (this.activePage * this.rowsOnPage - this.rowsOnPage + 1);
  //       this.itemsTotal = this.itemsTotal - 1;

  //       if (!(this.itemsTotal >= start)) {
  //         this.activePage = this.activePage - 1;
  //       }
  //       this.toastr.success(res.message);
  //       /* reload page. */
  //       this.getBlogs();
  //     } else {
  //       this._commanService.loader('hide');
  //       this.toastr.error(res.message, 'Error');
  //       this._commanService.checkAccessToken(res.error);
  //       this.getBlogs();
  //     }
  //   }, err => {
  //     this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //   });
  // }

  /* Function use to remove Blog with blog id */
  // removeBlog(blogID) {
  //   if (confirm('Do you want to delete?')) {
  //     this._commanService.loader('show');
  //     this._blogService.delete(blogID).subscribe((res: any) => {
  //       this._commanService.loader('hide');
  //       if (res.statusCode === 200) {
  //         this.response = res;
  //         const start = (this.activePage * this.rowsOnPage - this.rowsOnPage + 1);
  //         this.itemsTotal = this.itemsTotal - 1;

  //         if (!(this.itemsTotal >= start)) {
  //           this.activePage = this.activePage - 1;
  //         }
  //         this.toastr.success(res.message);
  //         /* reload page. */
  //         this.getBlogs();
  //       } else {
  //         this._commanService.loader('hide');
  //         this.toastr.error(res.message, 'Error');
  //       }
  //     }, err => {
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  /*Get all Blogs*/
  getBlogs(): void {
    this._commanService.loader('show');

    // let language   =  this.lang;

    this._blogService.getAllContents().subscribe((res: any) => {
      this._commanService.loader('hide');
      if (res.statusCode === 200) {
        this.data = res.data.data;
        this.dataPerPage = this.data.slice(0, this.rowsOnPage);
        this.itemsTotal = this.data.length;
        this.data.forEach(d => {
          const date = new Date(d.createdAt);
          const today = new Date();
          const idSA = environment.config.idSA;


            if(today.getTime() - date.getTime() < 24 * 60 * 60 * 1000) {
              if(idSA != d.author._id) {
                this.counts.today ++;
              } else {
                this.counts.todayBySA ++;
              }
            }
  
            if(today.getTime() - date.getTime() < 7 * 24 * 60 * 60 * 1000) {
              if(idSA != d.author._id) {
                this.counts.thisWeek ++;
              } else {
                this.counts.thisWeekBySA ++;
              }
            }
  
            if(today.getTime() - date.getTime() < 30 * 24 * 60 * 60 * 1000) {
              if(idSA != d.author._id) {
                this.counts.thisMonth ++;
              } else {
                this.counts.thisMonthBySA ++;
              }
            }

            if(d.author){
              if(this.countsAuthor[d.author._id]) {
                this.countsAuthor[d.author._id].count ++
              } else {
                this.countsAuthor[d.author._id] = {
                  count: 1,
                  author: d.author
                };
              }  
            }
        });
        const list = []
        for (let key in this.countsAuthor) {
          list.push(this.countsAuthor[key]);
        }
        this.countsAuthorRanking = list.sort((a,b) => b.count - a.count);
        
        this.itemsTotal = res.data.total || 0;
        this.removeHTML();
      } else {
        this._commanService.loader('hide');
        this._commanService.checkAccessToken(res.error);
      }
    }, err => {
      this.toastr.error('There are some errors, please try again after some time !', 'Error');
    });
  }

  public onChangePage(e) {
    this.rowsOnPage = e.itemsPerPage;
    this.activePage = e.page;
    this.dataPerPage = this.data.slice((e.page - 1) * this.rowsOnPage, e.page * this.rowsOnPage);
  }


  public onPageChange(event) {
    this.rowsOnPage = event.rowsOnPage;
    this.activePage = event.activePage;
    this.getBlogs();
  }

  public search(event, element = 'input') {
    if (element == 'input') {
      if (event.keyCode == 13 || this.searchTerm == '') {
        this.searchTerm = this.searchTerm.trim();
        this._commanService.loader('show');
        this.getBlogs();
        this.activePage = 1;
        this.getBlogs();
      }
    } else {
      this.searchTerm = this.searchTerm.trim();
      this._commanService.loader('show');
      this.getBlogs();
      this.activePage = 1;
      this.getBlogs();
    }
  }


  removeHTML() {
    if (this.data) {
      for (let j = 0; j < this.data.length; ++j) {
        if (this.data[j].title && this.data[j].title.length > 15) { this.data[j].title = this.data[j].title.substring(0, 15) + ' ...'; }
        if (this.data[j].description) { this.data[j].description = this.data[j].description.replace(/<\/?[^>]+(>|$)/g, ''); }
        if (this.data[j].description) { this.data[j].description = this.data[j].description.replace(/&.*;/g, ''); }
        if (this.data[j].description && this.data[j].description.length > 100) { this.data[j].description = this.data[j].description.substring(0, 100) + ' ...'; }
      }
    }

  }
}
